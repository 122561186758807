<template>
  <svg width="21px" height="21px" class="oscityIcon" viewBox="0 0 15.95 14.18">
    <path
      d="M15.77,12.19,9.13.66a1.33,1.33,0,0,0-2.3,0L.18,12.19a1.33,1.33,0,0,0,1.15,2H14.62A1.33,1.33,0,0,0,15.77,12.19ZM6.53,4.05A.68.68,0,0,1,7,3.84H8.94a.67.67,0,0,1,.48.21.64.64,0,0,1,.18.49L9.38,8.67a.66.66,0,0,1-.67.63H7.24a.65.65,0,0,1-.66-.63L6.35,4.54A.68.68,0,0,1,6.53,4.05ZM8,12.88a1.77,1.77,0,1,1,1.77-1.77A1.78,1.78,0,0,1,8,12.88Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AlertIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
