/* eslint-disable */

import MenuService from '@/services/MenuService'

export const mutations = {
  SET_MENU(state, menu) {
    state.menu = menu
  }
}

export const actions = {
  async get({ commit }) {
    const { list } = await MenuService.list()
    commit('SET_MENU', list)
  }
}
