let client

export function setClientMap(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/transparency/map/create', data)
  },
  update: data => {
    return client.$put('/transparency/map/update', data)
  },
  get: id => {
    return client.$post('/transparency/map/get/' + id)
  },
  list: data => {
    return client.$post('/transparency/map/list', data)
  },
  delete: urlsafe => {
    return client.$post('/transparency/map/delete/' + urlsafe)
  },
  changeType: data => {
    return client.$post('/transparency/map/change-type', data)
  },
  changeSector: data => {
    return client.$post('/transparency/map/change-sector', data)
  },
  changeCategory: data => {
    return client.$post('/transparency/map/change-category', data)
  },
  deleteByType: urlsafe => {
    return client.$post('/transparency/map/delete-by-type/' + urlsafe)
  },
  deleteBySector: urlsafe => {
    return client.$post('/transparency/map/delete-by-sector/' + urlsafe)
  },
  deleteByCategory: urlsafe => {
    return client.$post('/transparency/map/delete-by-category/' + urlsafe)
  }
}
