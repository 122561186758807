let client

export function setClientAttachment(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/attachments/create', data)
  },
  get: id => {
    return client.$post('/attachments/get/' + id)
  }
}
