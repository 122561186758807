<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    x="0px"
    y="0px"
    viewBox="0 0 100 125"
    enable-background="new 0 0 100 100"
    xml:space="preserve"
  >
    <path
      d="M59.606,72.23c7.593-6.262,11.498-12.147,13.797-19.517c1.652-0.561,3.186-2.107,3.951-4.247  c1.037-2.894,0.312-5.853-1.596-7.067c0.242-1.762,0.459-3.602,0.666-5.553c1.996-19.034-6.678-29.582-26.427-27.928  c-5.192,0.436-8.589,3.328-8.589,3.328c-13.736-3-19.52,10.511-17.84,24.602c0.234,1.936,0.469,3.768,0.725,5.523  c-1.945,1.187-2.701,4.174-1.654,7.096c0.785,2.193,2.375,3.755,4.069,4.281c2.285,7.352,6.103,13.24,13.681,19.479  C21.173,77.032,1.589,84.243,1.589,91.448v0.803h96.819v-0.803h0.003C98.41,84.243,78.825,77.032,59.606,72.23z M52.897,61.108  c-0.743,0.715-1.727,1.074-2.947,1.074c-1.223,0-2.205-0.359-2.949-1.074c-0.744-0.719-1.115-1.627-1.115-2.728  c0-1.117,0.381-2.037,1.142-2.765c0.761-0.725,1.735-1.088,2.923-1.088s2.162,0.363,2.923,1.088  c0.761,0.728,1.142,1.646,1.142,2.765C54.015,59.481,53.644,60.39,52.897,61.108z M55.32,44.864  c-1.271,1.222-1.999,2.65-2.183,4.295l-0.102,1.531h-6.424c0-2.347,0.285-4.213,0.854-5.602c0.568-1.393,1.609-2.76,3.123-4.105  c1.514-1.352,2.523-2.447,3.024-3.293c0.501-0.845,0.753-1.738,0.753-2.677c0-2.832-1.305-4.249-3.916-4.249  c-1.238,0-2.229,0.38-2.973,1.145c-0.746,0.764-1.135,1.815-1.168,3.154h-7.276c0.032-3.195,1.065-5.699,3.101-7.507  c2.031-1.812,4.805-2.715,8.317-2.715c3.547,0,6.299,0.856,8.257,2.577c1.957,1.714,2.936,4.14,2.936,7.269  c0,1.427-0.317,2.769-0.953,4.032c-0.635,1.268-1.748,2.668-3.338,4.207L55.32,44.864z"
    />
  </svg>
</template>

<script>
export default {
  name: 'AnonymousIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
