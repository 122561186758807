let client

export function setClientMaterials(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/listener/materials/create', data)
  },
  update: data => {
    return client.$put('/listener/materials/update', data)
  },
  list: data => {
    return client.$post('/listener/materials/list')
  }
}
