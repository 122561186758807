import Auth from './auth'

import './middleware'

// Active schemes
import scheme_5fc5ffd8 from './schemes/firebase.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":true,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":false,"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"firebase"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // firebase
  $auth.registerStrategy('firebase', new scheme_5fc5ffd8($auth, {"_name":"firebase"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
