<template>
  <svg width="17px" height="17px" class="oscityIcon" viewBox="0 0 12.01 13.78">
    <path
      d="M2,13.58a.93.93,0,0,1-.93-.93V3.75h9.83v8.9a.93.93,0,0,1-.93.93ZM8.66,4.72a.85.85,0,0,0-.84.84v6.2a.85.85,0,1,0,1.69,0V5.56A.85.85,0,0,0,8.66,4.72ZM6,4.72a.85.85,0,0,0-.84.84v6.2a.85.85,0,1,0,1.69,0V5.56A.85.85,0,0,0,6,4.72Zm-2.65,0a.85.85,0,0,0-.85.84v6.2a.85.85,0,1,0,1.69,0V5.56A.85.85,0,0,0,3.35,4.72ZM.24,2.06l0-.93,3.61,0L4.18.35A.28.28,0,0,1,4.42.2H7.59a.25.25,0,0,1,.23.15l.37.74h3.57l.05.93-.05,0Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DeleteIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
