let client

export function setClientColonias(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/urbanism/colonias/create', data)
  },
  update: data => {
    return client.$put('/urbanism/colonias/update', data)
  },
  list: data => {
    return client.$post('/urbanism/colonias/list')
  }
}
