import * as firebase from 'firebase'
export default (context, inject) => {
  const config = {
    apiKey: context.env.ST_FIREBASE_API_KEY, // REQUIRED
    authDomain:
      context.env.ST_FIREBASE_AUTH_DOMAIN || 'rio-1a6b2.firebaseapp.com',
    databaseURL:
      context.env.ST_FIREBASE_DATABASE_URL ||
      'https://rio-1a6b2.firebaseio.com/',
    projectId: context.env.ST_FIREBASE_PROJECT_ID || 'rio-1a6b2',
    storageBucket:
      context.env.ST_FIREBASE_STORAGE_BUCKET || 'rio-1a6b2.appspot.com',
    messagingSenderId:
      context.env.ST_FIREBASE_MESSAGING_SENDER_ID || '974461469654',
    appId:
      context.env.ST_FIREBASE_APP_ID ||
      '1:974461469654:web:b330c794c31c25bca65a77'
  }

  if (firebase.apps.length < 2) {
    firebase.initializeApp(config, 'singletally')
  }
  const singletally = firebase.app('singletally')
  singletally.auth()
  singletally.storage()
  singletally.firestore()
  inject('firebaseSingletally', singletally)
  // inject('firebaseui', new firebaseui.auth.AuthUI(firebase.auth()))
}
