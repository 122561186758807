let client

export function setClientMenu(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/menu/create', data)
  },
  update: data => {
    return client.$post('/menu/update', data)
  },
  list: () => {
    return client.$post('/menu/list')
  },
  delete: id => {
    return client.$post('/menu/delete/' + id)
  }
}
