<template>
  <svg width="21px" height="21px" class="oscityIcon" viewBox="0 0 16.17 14">
    <path
      d="M12.12,0H4L0,7l4,7h8.08l4.05-7Zm-.49,9.18A1.31,1.31,0,0,1,11.47,11a1.33,1.33,0,0,1-.84.3,1.31,1.31,0,0,1-1-.46L8.08,9,6.53,10.86a1.31,1.31,0,0,1-1,.46,1.37,1.37,0,0,1-.84-.3,1.32,1.32,0,0,1-.15-1.84L6.38,7,4.54,4.81A1.31,1.31,0,0,1,4.69,3a1.31,1.31,0,0,1,1.84.15L8.08,5,9.64,3.13a1.31,1.31,0,0,1,1-.46,1.28,1.28,0,0,1,.84.31,1.3,1.3,0,0,1,.16,1.83L9.78,7Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DefeatedIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
