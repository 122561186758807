/* eslint-disable */

import Vue from 'vue'
import async from 'async'
import firebase from 'firebase/app'
import 'firebase/auth'

import UserService from '@/services/UserService'

export const mutations = {
  SET_USER_ID(state, id) {
    Vue.set(state, 'userId', parseInt(id))
  },
  SET_ID_TOKEN(state, idToken) {
    Vue.set(state, 'idToken', idToken)
  },
  SET_USER_UID(state, uid) {
    Vue.set(state, 'userUid', uid)
  },
  SET_USER(state, user) {
    Vue.set(state, 'user', user)
  },
  SET_USER_ROLE(state, role) {
    Vue.set(state.user, 'role', role)
  },
  CLEAR_USER(state) {
    Vue.set(state.user, 'user', null)
    Vue.set(state.user, 'userId', null)
    Vue.set(state.user, 'userUid', null)
    Vue.set(state.user, 'idToken', null)
  }
}

export const actions = {
  fetchUser({ commit }, authData) {
    if (authData.idToken && authData.userUid) {
      commit('SET_ID_TOKEN', authData.idToken)
      commit('SET_USER_UID', authData.userUid)
    }

    commit('SET_USER', {
      email: authData.email,
      emailVerified: authData.emailVerified,
      displayName: authData.displayName,
      photoURL: authData.photoURL,
      phoneNumber: authData.phoneNumber,
      role: authData.role
    })
  },
  getUser({ commit }, uid) {
    return new Promise((resolve, reject) => {
      UserService.getByUid(uid)
        .then(res => {
          if (res && res.list && res.list.length > 0) {
            if (res.list[0].id) {
              commit('SET_USER_ID', res.list[0].id)
            }
            if (res.list[0].role) {
              commit('SET_USER_ROLE', res.list[0].role)
            }
          }
          resolve()
        })
        .catch(err => {
          console.error(err)
          reject(err)
        })
    })
  },
  signup({ dispatch }, authData) {
    dispatch('wait/start', 'full', { root: true })
    return new Promise((resolve, reject) => {
      async.waterfall(
        [
          callback => {
            firebase
              .auth()
              .createUserWithEmailAndPassword(authData.email, authData.password)
              .then(user => {
                user.user.sendEmailVerification()
                callback(null, user.user)
              })
              .catch(err => {
                callback(err)
              })
          },
          (user, callback) => {
            this.$auth.setRefreshToken('firebase', user.refreshToken)
            user.getIdToken().then(idToken => {
              this.$auth.setToken('firebase', idToken)
              this.$axios.setToken(idToken, 'Bearer')
              callback(null, user)
            })
          },
          (user, callback) => {
            UserService.getByEmail({ email: user.email })
              .then(res => {
                let userDb = null
                if (res) {
                  userDb = res
                }
                callback(null, user, userDb)
              })
              .catch(err => {
                callback(err)
              })
          },
          (user, userDb, callback) => {
            if (userDb && userDb.id) {
              UserService.update({
                id: userDb.id,
                uid: user.uid,
                first_name: userDb.first_name,
                last_name: userDb.last_name,
                email: userDb.email,
                phone: userDb.phone,
                role: userDb.role.id
              })
                .then(res => {
                  this.$auth.setUser({
                    email: user.email,
                    emailVerified: user.emailVerified,
                    displayName: user.displayName
                      ? user.displayName
                      : userDb.first_name,
                    photoURL: user.photoURL,
                    phoneNumber: user.phoneNumber,
                    role: res.role
                  })
                  user
                    .updateProfile({
                      displayName: userDb.first_name
                    })
                    .then(() => {
                      callback(null, user)
                    })
                    .catch(err => {
                      callback(err)
                    })
                })
                .catch(err => {
                  callback(err)
                })
            } else {
              UserService.create({
                uid: user.uid,
                email: authData.email
              })
                .then(res => {
                  this.$auth.setUser({
                    email: user.email,
                    emailVerified: user.emailVerified,
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                    phoneNumber: user.phoneNumber,
                    role: res.role
                  })
                  callback(null, user)
                })
                .catch(err => {
                  callback(err)
                })
            }
          }
        ],
        (err, result) => {
          dispatch('wait/end', 'full', { root: true })
          if (err) {
            console.error(err)
            reject(err)
          } else {
            console.log('end register')
            resolve()
          }
        }
      )
    })
  },
  sendCode({ dispatch }, dataRecaptcha) {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithPhoneNumber(dataRecaptcha.phone, dataRecaptcha.verifier)
        .then(confirmationResult => {
          window.confirmationResult = confirmationResult
          resolve(confirmationResult)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  loginPhone({ commit, dispatch }, authData) {
    dispatch('wait/start', 'full', { root: true })

    return new Promise((resolve, reject) => {
      async.waterfall(
        [
          callback => {
            window.confirmationResult
              .confirm(authData.id)
              .then(function(result) {
                callback(null, result.user)
              })
              .catch(function(err) {
                callback(err)
              })
          },
          (user, callback) => {
            user.getIdToken().then(idToken => {
              commit('SET_ID_TOKEN', idToken)
              callback(null, user, idToken)
            })
          },
          (user, idToken, callback) => {
            let phone = user.phoneNumber

            if (phone && phone.length === 14) {
              phone = phone.substr(4)
            }

            UserService.getByPhone(phone)
              .then(res => {
                let response = null

                if (
                  res.list &&
                  Array.isArray(res.list) &&
                  res.list.length > 0
                ) {
                  response = res.list[0]
                }
                callback(null, response, user, idToken)
              })
              .catch(err => {
                callback(err)
              })
          },
          (userdb, user, idToken, callback) => {
            if (!userdb || userdb === {} || userdb.length === 0) {
              UserService.create({
                uid: user.uid,
                phone: user.phoneNumber,
                email: null
              })
                .then(res => {
                  commit('SET_ID_TOKEN', idToken)
                  commit('SET_USER_UID', user.uid)
                  commit('SET_USER_ID', res.id)
                  commit('SET_USER', {
                    email: user.email,
                    emailVerified: user.emailVerified,
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                    phoneNumber: user.phoneNumber,
                    role: user.role
                  })

                  callback(null, user)
                })
                .catch(err => {
                  callback(err)
                })
            } else {
              commit('SET_ID_TOKEN', idToken)
              commit('SET_USER_UID', user.uid)
              commit('SET_USER_ID', userdb.id)
              commit('SET_USER', {
                email: user.email,
                emailVerified: user.emailVerified,
                displayName: user.displayName,
                photoURL: user.photoURL,
                phoneNumber: user.phoneNumber,
                role: user.role
              })

              callback(null, user)
            }
          }
        ],
        (err, result) => {
          dispatch('wait/end', 'full', { root: true })

          if (err) {
            console.error(err)
            reject(err)
          } else {
            resolve(result)
          }
        }
      )
    })
  },
  login({ dispatch }, authData) {
    return new Promise((resolve, reject) => {
      dispatch('wait/start', 'full', { root: true })
      async.waterfall(
        [
          callback => {
            firebase
              .auth()
              .signInWithEmailAndPassword(authData.email, authData.password)
              .then(user => {
                callback(null, user.user)
              })
              .catch(err => {
                callback(err)
              })
          },
          (user, callback) => {
            user.getIdToken().then(idToken => {
              callback(null, { idToken: idToken, user: user })
            })
          }
        ],
        (err, result) => {
          dispatch('wait/end', 'full', { root: true })
          if (err) {
            console.error(err)
            reject(err)
          } else {
            resolve(result)
          }
        }
      )
      /*
      firebase.auth().signInWithEmailAndPassword(authData.email, authData.password).then((user) => {
        if (user) {
          user.getIdToken().then((idToken) => {
            commit('SET_ID_TOKEN', idToken)
            commit('SET_USER_UID', user.uid)
            commit('SET_USER', {
              email: user.email,
              emailVerified: user.emailVerified,
              displayName: user.displayName,
              photoURL: user.photoURL,
              phoneNumber: user.phoneNumber,
              role: user.role
            })
            dispatch('SET_ID_TOKEN', idToken)
            dispatch('wait/end', 'full', { root: true })
            resolve(user)
          })
        }
      }).catch((err) => {
        reject(err)
      })
      */
    })
  },
  logout({ dispatch }) {
    dispatch('wait/start', 'full', { root: true })
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch('wait/end', 'full', { root: true })
      })
      .catch(err => {
        console.error(err)
      })
  }
}
