<template>
  <svg width="21px" height="21px" class="oscityIcon" viewBox="0 0 16.17 14">
    <path
      d="M12.12,0H4L0,7l4,7h8.08l4.05-7Zm0,9.3a4.23,4.23,0,0,1-2.5,2.42,5.77,5.77,0,0,1-.82.2L8.36,12h-.5l-.46-.08a4.73,4.73,0,0,1-.86-.22A4.17,4.17,0,0,1,3.82,8.37,4.26,4.26,0,0,1,6.55,3.63a1.2,1.2,0,0,1-.08-.35,1.28,1.28,0,0,1,.26-1A1.27,1.27,0,0,1,7.7,2h.84a1.17,1.17,0,0,1,.87.27,1.18,1.18,0,0,1,.27.88,1.27,1.27,0,0,1,0,.2,1,1,0,0,1,.65-.27,1,1,0,0,1,.6.21,4.08,4.08,0,0,1,.39.31.93.93,0,0,1,.15,1.31l0,0h0a5.72,5.72,0,0,1,.54.88A4.17,4.17,0,0,1,12.07,9.3Z"
    />
    <path
      d="M8.07,5.55a2.2,2.2,0,0,0-.83.17L7,5.86l1,.53A1.3,1.3,0,0,1,9.33,7.53a1.25,1.25,0,0,1-1,1.37,1.54,1.54,0,0,1-.3,0,1.29,1.29,0,0,1-1.19-.85L6,7.35A2,2,0,0,0,6,8,2.11,2.11,0,0,0,8.15,9.79h.1a2.15,2.15,0,0,0,1.95-2,2.06,2.06,0,0,0-.58-1.56A2.1,2.1,0,0,0,8.07,5.55Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CautionIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
