import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _edb94f6e = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _099d2bab = () => interopDefault(import('../pages/email-action-handler.vue' /* webpackChunkName: "pages/email-action-handler" */))
const _27927682 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _4ee9c93c = () => interopDefault(import('../pages/forbidden.vue' /* webpackChunkName: "pages/forbidden" */))
const _34a28752 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _730194fd = () => interopDefault(import('../pages/not-validated.vue' /* webpackChunkName: "pages/not-validated" */))
const _20caa104 = () => interopDefault(import('../pages/password-reset.vue' /* webpackChunkName: "pages/password-reset" */))
const _3f61389e = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _dd6a5d5c = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _281b0f6c = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _78795726 = () => interopDefault(import('../pages/admin/certificates/index.vue' /* webpackChunkName: "pages/admin/certificates/index" */))
const _83499606 = () => interopDefault(import('../pages/admin/dashboard.vue' /* webpackChunkName: "pages/admin/dashboard" */))
const _6afae43b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4348d208 = () => interopDefault(import('../pages/admin/ai/language.vue' /* webpackChunkName: "pages/admin/ai/language" */))
const _4fa7f898 = () => interopDefault(import('../pages/admin/ai/vision.vue' /* webpackChunkName: "pages/admin/ai/vision" */))
const _2227db30 = () => interopDefault(import('../pages/admin/certificates/analytics-data/index.vue' /* webpackChunkName: "pages/admin/certificates/analytics-data/index" */))
const _a0105616 = () => interopDefault(import('../pages/admin/certificates/analytics-extractos/index.vue' /* webpackChunkName: "pages/admin/certificates/analytics-extractos/index" */))
const _3d878758 = () => interopDefault(import('../pages/admin/certificates/analytics-ganadores/index.vue' /* webpackChunkName: "pages/admin/certificates/analytics-ganadores/index" */))
const _437ea948 = () => interopDefault(import('../pages/admin/certificates/analytics-minibingo/index.vue' /* webpackChunkName: "pages/admin/certificates/analytics-minibingo/index" */))
const _2133551e = () => interopDefault(import('../pages/admin/certificates/analytics-unificada/index.vue' /* webpackChunkName: "pages/admin/certificates/analytics-unificada/index" */))
const _e06cdb86 = () => interopDefault(import('../pages/admin/certificates/neuquen-certificates/index.vue' /* webpackChunkName: "pages/admin/certificates/neuquen-certificates/index" */))
const _4ff8f4ad = () => interopDefault(import('../pages/admin/certificates/neuquen-minibingo/index.vue' /* webpackChunkName: "pages/admin/certificates/neuquen-minibingo/index" */))
const _2dada083 = () => interopDefault(import('../pages/admin/certificates/neuquen-unificada/index.vue' /* webpackChunkName: "pages/admin/certificates/neuquen-unificada/index" */))
const _32d330d1 = () => interopDefault(import('../pages/admin/certificates/neuquen-win/index.vue' /* webpackChunkName: "pages/admin/certificates/neuquen-win/index" */))
const _08d8ff9e = () => interopDefault(import('../pages/admin/configurations/areas/index.vue' /* webpackChunkName: "pages/admin/configurations/areas/index" */))
const _53473c5f = () => interopDefault(import('../pages/admin/configurations/brand/index.vue' /* webpackChunkName: "pages/admin/configurations/brand/index" */))
const _60bf479d = () => interopDefault(import('../pages/admin/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/configurations/menu/index" */))
const _3a2b6d1f = () => interopDefault(import('../pages/admin/configurations/modules/index.vue' /* webpackChunkName: "pages/admin/configurations/modules/index" */))
const _01a517d6 = () => interopDefault(import('../pages/admin/configurations/roles/index.vue' /* webpackChunkName: "pages/admin/configurations/roles/index" */))
const _a7a6c03e = () => interopDefault(import('../pages/admin/configurations/settings/index.vue' /* webpackChunkName: "pages/admin/configurations/settings/index" */))
const _66238d2f = () => interopDefault(import('../pages/admin/configurations/urbanism/index.vue' /* webpackChunkName: "pages/admin/configurations/urbanism/index" */))
const _72a711a0 = () => interopDefault(import('../pages/admin/configurations/users/index.vue' /* webpackChunkName: "pages/admin/configurations/users/index" */))
const _22787aed = () => interopDefault(import('../pages/admin/listener/attention/index.vue' /* webpackChunkName: "pages/admin/listener/attention/index" */))
const _8d701e9c = () => interopDefault(import('../pages/admin/listener/citizens/index.vue' /* webpackChunkName: "pages/admin/listener/citizens/index" */))
const _23f94e12 = () => interopDefault(import('../pages/admin/listener/complaint.vue' /* webpackChunkName: "pages/admin/listener/complaint" */))
const _7b1f68e3 = () => interopDefault(import('../pages/admin/listener/dashboard/index.vue' /* webpackChunkName: "pages/admin/listener/dashboard/index" */))
const _209a0b28 = () => interopDefault(import('../pages/admin/listener/new.vue' /* webpackChunkName: "pages/admin/listener/new" */))
const _4883b724 = () => interopDefault(import('../pages/admin/listener/publicservices/index.vue' /* webpackChunkName: "pages/admin/listener/publicservices/index" */))
const _7136bd9a = () => interopDefault(import('../pages/admin/transparency/initiatives/index.vue' /* webpackChunkName: "pages/admin/transparency/initiatives/index" */))
const _114758cf = () => interopDefault(import('../pages/admin/transparency/map/index.vue' /* webpackChunkName: "pages/admin/transparency/map/index" */))
const _80a11c86 = () => interopDefault(import('../pages/admin/urbanism/obras/index.vue' /* webpackChunkName: "pages/admin/urbanism/obras/index" */))
const _d8c8e682 = () => interopDefault(import('../pages/citizen/listener/create-v1.vue' /* webpackChunkName: "pages/citizen/listener/create-v1" */))
const _d8acb780 = () => interopDefault(import('../pages/citizen/listener/create-v2.vue' /* webpackChunkName: "pages/citizen/listener/create-v2" */))
const _d890887e = () => interopDefault(import('../pages/citizen/listener/create-v3.vue' /* webpackChunkName: "pages/citizen/listener/create-v3" */))
const _66d3001b = () => interopDefault(import('../pages/citizen/listener/list.vue' /* webpackChunkName: "pages/citizen/listener/list" */))
const _32899d6f = () => interopDefault(import('../pages/citizen/listener/map.vue' /* webpackChunkName: "pages/citizen/listener/map" */))
const _ff4006c4 = () => interopDefault(import('../pages/citizen/listener/my-reports/index.vue' /* webpackChunkName: "pages/citizen/listener/my-reports/index" */))
const _4b5b7f21 = () => interopDefault(import('../pages/citizen/transparency/initiatives/index.vue' /* webpackChunkName: "pages/citizen/transparency/initiatives/index" */))
const _724a6513 = () => interopDefault(import('../pages/citizen/transparency/map.vue' /* webpackChunkName: "pages/citizen/transparency/map" */))
const _fa2fbff8 = () => interopDefault(import('../pages/citizen/urbanism/obras/index.vue' /* webpackChunkName: "pages/citizen/urbanism/obras/index" */))
const _32f88688 = () => interopDefault(import('../pages/admin/certificates/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/certificates/configurations/menu/index" */))
const _2e6dbe1e = () => interopDefault(import('../pages/admin/configurations/areas/area.vue' /* webpackChunkName: "pages/admin/configurations/areas/area" */))
const _54987792 = () => interopDefault(import('../pages/admin/configurations/modules/menu-certificates.vue' /* webpackChunkName: "pages/admin/configurations/modules/menu-certificates" */))
const _545befd3 = () => interopDefault(import('../pages/admin/configurations/modules/menu-transparency.vue' /* webpackChunkName: "pages/admin/configurations/modules/menu-transparency" */))
const _f20a2728 = () => interopDefault(import('../pages/admin/configurations/modules/menu-urbanism.vue' /* webpackChunkName: "pages/admin/configurations/modules/menu-urbanism" */))
const _0e2a9786 = () => interopDefault(import('../pages/admin/configurations/urbanism/edit.vue' /* webpackChunkName: "pages/admin/configurations/urbanism/edit" */))
const _032b5e0e = () => interopDefault(import('../pages/admin/listener/attention/create-activities.vue' /* webpackChunkName: "pages/admin/listener/attention/create-activities" */))
const _f9ee8c48 = () => interopDefault(import('../pages/admin/listener/attention/create-institution.vue' /* webpackChunkName: "pages/admin/listener/attention/create-institution" */))
const _46b7ed50 = () => interopDefault(import('../pages/admin/listener/attention/create-materials.vue' /* webpackChunkName: "pages/admin/listener/attention/create-materials" */))
const _7245c218 = () => interopDefault(import('../pages/admin/listener/attention/create-streets.vue' /* webpackChunkName: "pages/admin/listener/attention/create-streets" */))
const _cfc89a06 = () => interopDefault(import('../pages/admin/listener/configurations/categories/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/categories/index" */))
const _23cf1700 = () => interopDefault(import('../pages/admin/listener/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/menu/index" */))
const _4e058fd4 = () => interopDefault(import('../pages/admin/listener/configurations/organization/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/index" */))
const _d081c848 = () => interopDefault(import('../pages/admin/listener/configurations/trackers/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/trackers/index" */))
const _7f5e3b0f = () => interopDefault(import('../pages/admin/listener/socialnetworks/facebook.vue' /* webpackChunkName: "pages/admin/listener/socialnetworks/facebook" */))
const _4c705e8c = () => interopDefault(import('../pages/admin/listener/socialnetworks/twitter.vue' /* webpackChunkName: "pages/admin/listener/socialnetworks/twitter" */))
const _484eb64a = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/index" */))
const _8a51b1b8 = () => interopDefault(import('../pages/admin/transparency/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/menu/index" */))
const _c020cc2e = () => interopDefault(import('../pages/admin/transparency/initiatives/add.vue' /* webpackChunkName: "pages/admin/transparency/initiatives/add" */))
const _03310344 = () => interopDefault(import('../pages/admin/transparency/map/add.vue' /* webpackChunkName: "pages/admin/transparency/map/add" */))
const _bd5a4706 = () => interopDefault(import('../pages/admin/urbanism/configurations/menu/index.vue' /* webpackChunkName: "pages/admin/urbanism/configurations/menu/index" */))
const _67860a68 = () => interopDefault(import('../pages/admin/urbanism/obras/add.vue' /* webpackChunkName: "pages/admin/urbanism/obras/add" */))
const _ecca3d2c = () => interopDefault(import('../pages/admin/urbanism/obras/create-neighborhood.vue' /* webpackChunkName: "pages/admin/urbanism/obras/create-neighborhood" */))
const _6ae862b8 = () => interopDefault(import('../pages/admin/listener/configurations/organization/citizenattention.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/citizenattention" */))
const _07b9ea26 = () => interopDefault(import('../pages/admin/listener/attention/conformities/_id.vue' /* webpackChunkName: "pages/admin/listener/attention/conformities/_id" */))
const _4fce08f3 = () => interopDefault(import('../pages/admin/listener/attention/print/_id.vue' /* webpackChunkName: "pages/admin/listener/attention/print/_id" */))
const _63b90768 = () => interopDefault(import('../pages/admin/listener/configurations/categories/_id/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/categories/_id/index" */))
const _2281ef2c = () => interopDefault(import('../pages/admin/listener/configurations/organization/_secretary/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/_secretary/index" */))
const _1e20c796 = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/_id/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/_id/index" */))
const _c33f0494 = () => interopDefault(import('../pages/admin/listener/configurations/categories/_id/_subid.vue' /* webpackChunkName: "pages/admin/listener/configurations/categories/_id/_subid" */))
const _4423c93f = () => interopDefault(import('../pages/admin/listener/configurations/organization/_secretary/_agency/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/_secretary/_agency/index" */))
const _18153e4c = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/_id/_subid/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/_id/_subid/index" */))
const _7ea5b9f3 = () => interopDefault(import('../pages/admin/listener/configurations/organization/_secretary/_agency/_operator/index.vue' /* webpackChunkName: "pages/admin/listener/configurations/organization/_secretary/_agency/_operator/index" */))
const _e769f4ba = () => interopDefault(import('../pages/admin/transparency/configurations/catalog/_id/_subid/_deepid/index.vue' /* webpackChunkName: "pages/admin/transparency/configurations/catalog/_id/_subid/_deepid/index" */))
const _524377bd = () => interopDefault(import('../pages/admin/configurations/roles/_id.vue' /* webpackChunkName: "pages/admin/configurations/roles/_id" */))
const _dbe607f0 = () => interopDefault(import('../pages/admin/configurations/users/_id.vue' /* webpackChunkName: "pages/admin/configurations/users/_id" */))
const _1dd25495 = () => interopDefault(import('../pages/admin/listener/attention/_id.vue' /* webpackChunkName: "pages/admin/listener/attention/_id" */))
const _3eab5e82 = () => interopDefault(import('../pages/admin/transparency/initiatives/_id.vue' /* webpackChunkName: "pages/admin/transparency/initiatives/_id" */))
const _c5b97a12 = () => interopDefault(import('../pages/admin/transparency/map/_id.vue' /* webpackChunkName: "pages/admin/transparency/map/_id" */))
const _6af8bf65 = () => interopDefault(import('../pages/admin/urbanism/obras/_id.vue' /* webpackChunkName: "pages/admin/urbanism/obras/_id" */))
const _66608e86 = () => interopDefault(import('../pages/citizen/listener/my-reports/_id.vue' /* webpackChunkName: "pages/citizen/listener/my-reports/_id" */))
const _592fac7e = () => interopDefault(import('../pages/citizen/listener/success/_id.vue' /* webpackChunkName: "pages/citizen/listener/success/_id" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contact",
    component: _edb94f6e,
    name: "contact___es"
  }, {
    path: "/email-action-handler",
    component: _099d2bab,
    name: "email-action-handler___es"
  }, {
    path: "/faq",
    component: _27927682,
    name: "faq___es"
  }, {
    path: "/forbidden",
    component: _4ee9c93c,
    name: "forbidden___es"
  }, {
    path: "/login",
    component: _34a28752,
    name: "login___es"
  }, {
    path: "/not-validated",
    component: _730194fd,
    name: "not-validated___es"
  }, {
    path: "/password-reset",
    component: _20caa104,
    name: "password-reset___es"
  }, {
    path: "/privacy",
    component: _3f61389e,
    name: "privacy___es"
  }, {
    path: "/profile",
    component: _dd6a5d5c,
    name: "profile___es"
  }, {
    path: "/register",
    component: _281b0f6c,
    name: "register___es"
  }, {
    path: "/admin/certificates",
    component: _78795726,
    name: "admin-certificates___es"
  }, {
    path: "/admin/dashboard",
    component: _83499606,
    name: "admin-dashboard___es"
  }, {
    path: "/en/",
    component: _6afae43b,
    name: "index___en"
  }, {
    path: "/en/contact",
    component: _edb94f6e,
    name: "contact___en"
  }, {
    path: "/en/email-action-handler",
    component: _099d2bab,
    name: "email-action-handler___en"
  }, {
    path: "/en/faq",
    component: _27927682,
    name: "faq___en"
  }, {
    path: "/en/forbidden",
    component: _4ee9c93c,
    name: "forbidden___en"
  }, {
    path: "/en/login",
    component: _34a28752,
    name: "login___en"
  }, {
    path: "/en/not-validated",
    component: _730194fd,
    name: "not-validated___en"
  }, {
    path: "/en/password-reset",
    component: _20caa104,
    name: "password-reset___en"
  }, {
    path: "/en/privacy",
    component: _3f61389e,
    name: "privacy___en"
  }, {
    path: "/en/profile",
    component: _dd6a5d5c,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _281b0f6c,
    name: "register___en"
  }, {
    path: "/admin/ai/language",
    component: _4348d208,
    name: "admin-ai-language___es"
  }, {
    path: "/admin/ai/vision",
    component: _4fa7f898,
    name: "admin-ai-vision___es"
  }, {
    path: "/admin/certificates/analytics-data",
    component: _2227db30,
    name: "admin-certificates-analytics-data___es"
  }, {
    path: "/admin/certificates/analytics-extractos",
    component: _a0105616,
    name: "admin-certificates-analytics-extractos___es"
  }, {
    path: "/admin/certificates/analytics-ganadores",
    component: _3d878758,
    name: "admin-certificates-analytics-ganadores___es"
  }, {
    path: "/admin/certificates/analytics-minibingo",
    component: _437ea948,
    name: "admin-certificates-analytics-minibingo___es"
  }, {
    path: "/admin/certificates/analytics-unificada",
    component: _2133551e,
    name: "admin-certificates-analytics-unificada___es"
  }, {
    path: "/admin/certificates/neuquen-certificates",
    component: _e06cdb86,
    name: "admin-certificates-neuquen-certificates___es"
  }, {
    path: "/admin/certificates/neuquen-minibingo",
    component: _4ff8f4ad,
    name: "admin-certificates-neuquen-minibingo___es"
  }, {
    path: "/admin/certificates/neuquen-unificada",
    component: _2dada083,
    name: "admin-certificates-neuquen-unificada___es"
  }, {
    path: "/admin/certificates/neuquen-win",
    component: _32d330d1,
    name: "admin-certificates-neuquen-win___es"
  }, {
    path: "/admin/configurations/areas",
    component: _08d8ff9e,
    name: "admin-configurations-areas___es"
  }, {
    path: "/admin/configurations/brand",
    component: _53473c5f,
    name: "admin-configurations-brand___es"
  }, {
    path: "/admin/configurations/menu",
    component: _60bf479d,
    name: "admin-configurations-menu___es"
  }, {
    path: "/admin/configurations/modules",
    component: _3a2b6d1f,
    name: "admin-configurations-modules___es"
  }, {
    path: "/admin/configurations/roles",
    component: _01a517d6,
    name: "admin-configurations-roles___es"
  }, {
    path: "/admin/configurations/settings",
    component: _a7a6c03e,
    name: "admin-configurations-settings___es"
  }, {
    path: "/admin/configurations/urbanism",
    component: _66238d2f,
    name: "admin-configurations-urbanism___es"
  }, {
    path: "/admin/configurations/users",
    component: _72a711a0,
    name: "admin-configurations-users___es"
  }, {
    path: "/admin/listener/attention",
    component: _22787aed,
    name: "admin-listener-attention___es"
  }, {
    path: "/admin/listener/citizens",
    component: _8d701e9c,
    name: "admin-listener-citizens___es"
  }, {
    path: "/admin/listener/complaint",
    component: _23f94e12,
    name: "admin-listener-complaint___es"
  }, {
    path: "/admin/listener/dashboard",
    component: _7b1f68e3,
    name: "admin-listener-dashboard___es"
  }, {
    path: "/admin/listener/new",
    component: _209a0b28,
    name: "admin-listener-new___es"
  }, {
    path: "/admin/listener/publicservices",
    component: _4883b724,
    name: "admin-listener-publicservices___es"
  }, {
    path: "/admin/transparency/initiatives",
    component: _7136bd9a,
    name: "admin-transparency-initiatives___es"
  }, {
    path: "/admin/transparency/map",
    component: _114758cf,
    name: "admin-transparency-map___es"
  }, {
    path: "/admin/urbanism/obras",
    component: _80a11c86,
    name: "admin-urbanism-obras___es"
  }, {
    path: "/citizen/listener/create-v1",
    component: _d8c8e682,
    name: "citizen-listener-create-v1___es"
  }, {
    path: "/citizen/listener/create-v2",
    component: _d8acb780,
    name: "citizen-listener-create-v2___es"
  }, {
    path: "/citizen/listener/create-v3",
    component: _d890887e,
    name: "citizen-listener-create-v3___es"
  }, {
    path: "/citizen/listener/list",
    component: _66d3001b,
    name: "citizen-listener-list___es"
  }, {
    path: "/citizen/listener/map",
    component: _32899d6f,
    name: "citizen-listener-map___es"
  }, {
    path: "/citizen/listener/my-reports",
    component: _ff4006c4,
    name: "citizen-listener-my-reports___es"
  }, {
    path: "/citizen/transparency/initiatives",
    component: _4b5b7f21,
    name: "citizen-transparency-initiatives___es"
  }, {
    path: "/citizen/transparency/map",
    component: _724a6513,
    name: "citizen-transparency-map___es"
  }, {
    path: "/citizen/urbanism/obras",
    component: _fa2fbff8,
    name: "citizen-urbanism-obras___es"
  }, {
    path: "/en/admin/certificates",
    component: _78795726,
    name: "admin-certificates___en"
  }, {
    path: "/en/admin/dashboard",
    component: _83499606,
    name: "admin-dashboard___en"
  }, {
    path: "/admin/certificates/configurations/menu",
    component: _32f88688,
    name: "admin-certificates-configurations-menu___es"
  }, {
    path: "/admin/configurations/areas/area",
    component: _2e6dbe1e,
    name: "admin-configurations-areas-area___es"
  }, {
    path: "/admin/configurations/modules/menu-certificates",
    component: _54987792,
    name: "admin-configurations-modules-menu-certificates___es"
  }, {
    path: "/admin/configurations/modules/menu-transparency",
    component: _545befd3,
    name: "admin-configurations-modules-menu-transparency___es"
  }, {
    path: "/admin/configurations/modules/menu-urbanism",
    component: _f20a2728,
    name: "admin-configurations-modules-menu-urbanism___es"
  }, {
    path: "/admin/configurations/urbanism/edit",
    component: _0e2a9786,
    name: "admin-configurations-urbanism-edit___es"
  }, {
    path: "/admin/listener/attention/create-activities",
    component: _032b5e0e,
    name: "admin-listener-attention-create-activities___es"
  }, {
    path: "/admin/listener/attention/create-institution",
    component: _f9ee8c48,
    name: "admin-listener-attention-create-institution___es"
  }, {
    path: "/admin/listener/attention/create-materials",
    component: _46b7ed50,
    name: "admin-listener-attention-create-materials___es"
  }, {
    path: "/admin/listener/attention/create-streets",
    component: _7245c218,
    name: "admin-listener-attention-create-streets___es"
  }, {
    path: "/admin/listener/configurations/categories",
    component: _cfc89a06,
    name: "admin-listener-configurations-categories___es"
  }, {
    path: "/admin/listener/configurations/menu",
    component: _23cf1700,
    name: "admin-listener-configurations-menu___es"
  }, {
    path: "/admin/listener/configurations/organization",
    component: _4e058fd4,
    name: "admin-listener-configurations-organization___es"
  }, {
    path: "/admin/listener/configurations/trackers",
    component: _d081c848,
    name: "admin-listener-configurations-trackers___es"
  }, {
    path: "/admin/listener/socialnetworks/facebook",
    component: _7f5e3b0f,
    name: "admin-listener-socialnetworks-facebook___es"
  }, {
    path: "/admin/listener/socialnetworks/twitter",
    component: _4c705e8c,
    name: "admin-listener-socialnetworks-twitter___es"
  }, {
    path: "/admin/transparency/configurations/catalog",
    component: _484eb64a,
    name: "admin-transparency-configurations-catalog___es"
  }, {
    path: "/admin/transparency/configurations/menu",
    component: _8a51b1b8,
    name: "admin-transparency-configurations-menu___es"
  }, {
    path: "/admin/transparency/initiatives/add",
    component: _c020cc2e,
    name: "admin-transparency-initiatives-add___es"
  }, {
    path: "/admin/transparency/map/add",
    component: _03310344,
    name: "admin-transparency-map-add___es"
  }, {
    path: "/admin/urbanism/configurations/menu",
    component: _bd5a4706,
    name: "admin-urbanism-configurations-menu___es"
  }, {
    path: "/admin/urbanism/obras/add",
    component: _67860a68,
    name: "admin-urbanism-obras-add___es"
  }, {
    path: "/admin/urbanism/obras/create-neighborhood",
    component: _ecca3d2c,
    name: "admin-urbanism-obras-create-neighborhood___es"
  }, {
    path: "/en/admin/ai/language",
    component: _4348d208,
    name: "admin-ai-language___en"
  }, {
    path: "/en/admin/ai/vision",
    component: _4fa7f898,
    name: "admin-ai-vision___en"
  }, {
    path: "/en/admin/certificates/analytics-data",
    component: _2227db30,
    name: "admin-certificates-analytics-data___en"
  }, {
    path: "/en/admin/certificates/analytics-extractos",
    component: _a0105616,
    name: "admin-certificates-analytics-extractos___en"
  }, {
    path: "/en/admin/certificates/analytics-ganadores",
    component: _3d878758,
    name: "admin-certificates-analytics-ganadores___en"
  }, {
    path: "/en/admin/certificates/analytics-minibingo",
    component: _437ea948,
    name: "admin-certificates-analytics-minibingo___en"
  }, {
    path: "/en/admin/certificates/analytics-unificada",
    component: _2133551e,
    name: "admin-certificates-analytics-unificada___en"
  }, {
    path: "/en/admin/certificates/neuquen-certificates",
    component: _e06cdb86,
    name: "admin-certificates-neuquen-certificates___en"
  }, {
    path: "/en/admin/certificates/neuquen-minibingo",
    component: _4ff8f4ad,
    name: "admin-certificates-neuquen-minibingo___en"
  }, {
    path: "/en/admin/certificates/neuquen-unificada",
    component: _2dada083,
    name: "admin-certificates-neuquen-unificada___en"
  }, {
    path: "/en/admin/certificates/neuquen-win",
    component: _32d330d1,
    name: "admin-certificates-neuquen-win___en"
  }, {
    path: "/en/admin/configurations/areas",
    component: _08d8ff9e,
    name: "admin-configurations-areas___en"
  }, {
    path: "/en/admin/configurations/brand",
    component: _53473c5f,
    name: "admin-configurations-brand___en"
  }, {
    path: "/en/admin/configurations/menu",
    component: _60bf479d,
    name: "admin-configurations-menu___en"
  }, {
    path: "/en/admin/configurations/modules",
    component: _3a2b6d1f,
    name: "admin-configurations-modules___en"
  }, {
    path: "/en/admin/configurations/roles",
    component: _01a517d6,
    name: "admin-configurations-roles___en"
  }, {
    path: "/en/admin/configurations/settings",
    component: _a7a6c03e,
    name: "admin-configurations-settings___en"
  }, {
    path: "/en/admin/configurations/urbanism",
    component: _66238d2f,
    name: "admin-configurations-urbanism___en"
  }, {
    path: "/en/admin/configurations/users",
    component: _72a711a0,
    name: "admin-configurations-users___en"
  }, {
    path: "/en/admin/listener/attention",
    component: _22787aed,
    name: "admin-listener-attention___en"
  }, {
    path: "/en/admin/listener/citizens",
    component: _8d701e9c,
    name: "admin-listener-citizens___en"
  }, {
    path: "/en/admin/listener/complaint",
    component: _23f94e12,
    name: "admin-listener-complaint___en"
  }, {
    path: "/en/admin/listener/dashboard",
    component: _7b1f68e3,
    name: "admin-listener-dashboard___en"
  }, {
    path: "/en/admin/listener/new",
    component: _209a0b28,
    name: "admin-listener-new___en"
  }, {
    path: "/en/admin/listener/publicservices",
    component: _4883b724,
    name: "admin-listener-publicservices___en"
  }, {
    path: "/en/admin/transparency/initiatives",
    component: _7136bd9a,
    name: "admin-transparency-initiatives___en"
  }, {
    path: "/en/admin/transparency/map",
    component: _114758cf,
    name: "admin-transparency-map___en"
  }, {
    path: "/en/admin/urbanism/obras",
    component: _80a11c86,
    name: "admin-urbanism-obras___en"
  }, {
    path: "/en/citizen/listener/create-v1",
    component: _d8c8e682,
    name: "citizen-listener-create-v1___en"
  }, {
    path: "/en/citizen/listener/create-v2",
    component: _d8acb780,
    name: "citizen-listener-create-v2___en"
  }, {
    path: "/en/citizen/listener/create-v3",
    component: _d890887e,
    name: "citizen-listener-create-v3___en"
  }, {
    path: "/en/citizen/listener/list",
    component: _66d3001b,
    name: "citizen-listener-list___en"
  }, {
    path: "/en/citizen/listener/map",
    component: _32899d6f,
    name: "citizen-listener-map___en"
  }, {
    path: "/en/citizen/listener/my-reports",
    component: _ff4006c4,
    name: "citizen-listener-my-reports___en"
  }, {
    path: "/en/citizen/transparency/initiatives",
    component: _4b5b7f21,
    name: "citizen-transparency-initiatives___en"
  }, {
    path: "/en/citizen/transparency/map",
    component: _724a6513,
    name: "citizen-transparency-map___en"
  }, {
    path: "/en/citizen/urbanism/obras",
    component: _fa2fbff8,
    name: "citizen-urbanism-obras___en"
  }, {
    path: "/admin/listener/configurations/organization/citizenattention",
    component: _6ae862b8,
    name: "admin-listener-configurations-organization-citizenattention___es"
  }, {
    path: "/en/admin/certificates/configurations/menu",
    component: _32f88688,
    name: "admin-certificates-configurations-menu___en"
  }, {
    path: "/en/admin/configurations/areas/area",
    component: _2e6dbe1e,
    name: "admin-configurations-areas-area___en"
  }, {
    path: "/en/admin/configurations/modules/menu-certificates",
    component: _54987792,
    name: "admin-configurations-modules-menu-certificates___en"
  }, {
    path: "/en/admin/configurations/modules/menu-transparency",
    component: _545befd3,
    name: "admin-configurations-modules-menu-transparency___en"
  }, {
    path: "/en/admin/configurations/modules/menu-urbanism",
    component: _f20a2728,
    name: "admin-configurations-modules-menu-urbanism___en"
  }, {
    path: "/en/admin/configurations/urbanism/edit",
    component: _0e2a9786,
    name: "admin-configurations-urbanism-edit___en"
  }, {
    path: "/en/admin/listener/attention/create-activities",
    component: _032b5e0e,
    name: "admin-listener-attention-create-activities___en"
  }, {
    path: "/en/admin/listener/attention/create-institution",
    component: _f9ee8c48,
    name: "admin-listener-attention-create-institution___en"
  }, {
    path: "/en/admin/listener/attention/create-materials",
    component: _46b7ed50,
    name: "admin-listener-attention-create-materials___en"
  }, {
    path: "/en/admin/listener/attention/create-streets",
    component: _7245c218,
    name: "admin-listener-attention-create-streets___en"
  }, {
    path: "/en/admin/listener/configurations/categories",
    component: _cfc89a06,
    name: "admin-listener-configurations-categories___en"
  }, {
    path: "/en/admin/listener/configurations/menu",
    component: _23cf1700,
    name: "admin-listener-configurations-menu___en"
  }, {
    path: "/en/admin/listener/configurations/organization",
    component: _4e058fd4,
    name: "admin-listener-configurations-organization___en"
  }, {
    path: "/en/admin/listener/configurations/trackers",
    component: _d081c848,
    name: "admin-listener-configurations-trackers___en"
  }, {
    path: "/en/admin/listener/socialnetworks/facebook",
    component: _7f5e3b0f,
    name: "admin-listener-socialnetworks-facebook___en"
  }, {
    path: "/en/admin/listener/socialnetworks/twitter",
    component: _4c705e8c,
    name: "admin-listener-socialnetworks-twitter___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog",
    component: _484eb64a,
    name: "admin-transparency-configurations-catalog___en"
  }, {
    path: "/en/admin/transparency/configurations/menu",
    component: _8a51b1b8,
    name: "admin-transparency-configurations-menu___en"
  }, {
    path: "/en/admin/transparency/initiatives/add",
    component: _c020cc2e,
    name: "admin-transparency-initiatives-add___en"
  }, {
    path: "/en/admin/transparency/map/add",
    component: _03310344,
    name: "admin-transparency-map-add___en"
  }, {
    path: "/en/admin/urbanism/configurations/menu",
    component: _bd5a4706,
    name: "admin-urbanism-configurations-menu___en"
  }, {
    path: "/en/admin/urbanism/obras/add",
    component: _67860a68,
    name: "admin-urbanism-obras-add___en"
  }, {
    path: "/en/admin/urbanism/obras/create-neighborhood",
    component: _ecca3d2c,
    name: "admin-urbanism-obras-create-neighborhood___en"
  }, {
    path: "/en/admin/listener/configurations/organization/citizenattention",
    component: _6ae862b8,
    name: "admin-listener-configurations-organization-citizenattention___en"
  }, {
    path: "/en/admin/listener/attention/conformities/:id?",
    component: _07b9ea26,
    name: "admin-listener-attention-conformities-id___en"
  }, {
    path: "/en/admin/listener/attention/print/:id?",
    component: _4fce08f3,
    name: "admin-listener-attention-print-id___en"
  }, {
    path: "/en/admin/listener/configurations/categories/:id",
    component: _63b90768,
    name: "admin-listener-configurations-categories-id___en"
  }, {
    path: "/en/admin/listener/configurations/organization/:secretary",
    component: _2281ef2c,
    name: "admin-listener-configurations-organization-secretary___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog/:id",
    component: _1e20c796,
    name: "admin-transparency-configurations-catalog-id___en"
  }, {
    path: "/en/admin/listener/configurations/categories/:id/:subid",
    component: _c33f0494,
    name: "admin-listener-configurations-categories-id-subid___en"
  }, {
    path: "/en/admin/listener/configurations/organization/:secretary/:agency",
    component: _4423c93f,
    name: "admin-listener-configurations-organization-secretary-agency___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog/:id/:subid",
    component: _18153e4c,
    name: "admin-transparency-configurations-catalog-id-subid___en"
  }, {
    path: "/en/admin/listener/configurations/organization/:secretary/:agency/:operator",
    component: _7ea5b9f3,
    name: "admin-listener-configurations-organization-secretary-agency-operator___en"
  }, {
    path: "/en/admin/transparency/configurations/catalog/:id/:subid/:deepid",
    component: _e769f4ba,
    name: "admin-transparency-configurations-catalog-id-subid-deepid___en"
  }, {
    path: "/admin/listener/attention/conformities/:id?",
    component: _07b9ea26,
    name: "admin-listener-attention-conformities-id___es"
  }, {
    path: "/admin/listener/attention/print/:id?",
    component: _4fce08f3,
    name: "admin-listener-attention-print-id___es"
  }, {
    path: "/admin/listener/configurations/categories/:id",
    component: _63b90768,
    name: "admin-listener-configurations-categories-id___es"
  }, {
    path: "/admin/listener/configurations/organization/:secretary",
    component: _2281ef2c,
    name: "admin-listener-configurations-organization-secretary___es"
  }, {
    path: "/admin/transparency/configurations/catalog/:id",
    component: _1e20c796,
    name: "admin-transparency-configurations-catalog-id___es"
  }, {
    path: "/en/admin/configurations/roles/:id",
    component: _524377bd,
    name: "admin-configurations-roles-id___en"
  }, {
    path: "/en/admin/configurations/users/:id",
    component: _dbe607f0,
    name: "admin-configurations-users-id___en"
  }, {
    path: "/en/admin/listener/attention/:id",
    component: _1dd25495,
    name: "admin-listener-attention-id___en"
  }, {
    path: "/en/admin/transparency/initiatives/:id",
    component: _3eab5e82,
    name: "admin-transparency-initiatives-id___en"
  }, {
    path: "/en/admin/transparency/map/:id",
    component: _c5b97a12,
    name: "admin-transparency-map-id___en"
  }, {
    path: "/en/admin/urbanism/obras/:id",
    component: _6af8bf65,
    name: "admin-urbanism-obras-id___en"
  }, {
    path: "/en/citizen/listener/my-reports/:id?",
    component: _66608e86,
    name: "citizen-listener-my-reports-id___en"
  }, {
    path: "/en/citizen/listener/success/:id?",
    component: _592fac7e,
    name: "citizen-listener-success-id___en"
  }, {
    path: "/admin/listener/configurations/categories/:id/:subid",
    component: _c33f0494,
    name: "admin-listener-configurations-categories-id-subid___es"
  }, {
    path: "/admin/listener/configurations/organization/:secretary/:agency",
    component: _4423c93f,
    name: "admin-listener-configurations-organization-secretary-agency___es"
  }, {
    path: "/admin/transparency/configurations/catalog/:id/:subid",
    component: _18153e4c,
    name: "admin-transparency-configurations-catalog-id-subid___es"
  }, {
    path: "/admin/listener/configurations/organization/:secretary/:agency/:operator",
    component: _7ea5b9f3,
    name: "admin-listener-configurations-organization-secretary-agency-operator___es"
  }, {
    path: "/admin/transparency/configurations/catalog/:id/:subid/:deepid",
    component: _e769f4ba,
    name: "admin-transparency-configurations-catalog-id-subid-deepid___es"
  }, {
    path: "/admin/configurations/roles/:id",
    component: _524377bd,
    name: "admin-configurations-roles-id___es"
  }, {
    path: "/admin/configurations/users/:id",
    component: _dbe607f0,
    name: "admin-configurations-users-id___es"
  }, {
    path: "/admin/listener/attention/:id",
    component: _1dd25495,
    name: "admin-listener-attention-id___es"
  }, {
    path: "/admin/transparency/initiatives/:id",
    component: _3eab5e82,
    name: "admin-transparency-initiatives-id___es"
  }, {
    path: "/admin/transparency/map/:id",
    component: _c5b97a12,
    name: "admin-transparency-map-id___es"
  }, {
    path: "/admin/urbanism/obras/:id",
    component: _6af8bf65,
    name: "admin-urbanism-obras-id___es"
  }, {
    path: "/citizen/listener/my-reports/:id?",
    component: _66608e86,
    name: "citizen-listener-my-reports-id___es"
  }, {
    path: "/citizen/listener/success/:id?",
    component: _592fac7e,
    name: "citizen-listener-success-id___es"
  }, {
    path: "/",
    component: _6afae43b,
    name: "index___es"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
