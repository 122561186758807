import _ from 'lodash'

export default function(context) {
  if (!context.app.$auth.user.emailVerified && context.app.$auth.user.email) {
    return context.redirect('/not-validated')
  }
  if (
    (context.app.$auth.user.type !== 'superadmin' &&
      context.app.$auth.user.type !== 'user' &&
      (!context.app.$auth.user.role ||
        !context.app.$auth.user.role.views.includes('listener'))) ||
    _.find(context.store.state.module.modules, ['slug', 'listener']).active ===
      false
  ) {
    return context.redirect('/forbidden')
  }
}
