/* eslint-disable */

import _ from 'lodash'
import SettingService from '@/services/SettingService'

export const state = () => ({
  setting: {
    first_level_person: 'Director',
    first_level_plural: 'Direcciones',
    first_level_singular: 'Dirección',
    second_level_person: 'Subdirector',
    second_level_plural: 'Subdirecciones',
    second_level_singular: 'Subdirección',
    twitter: '@oscitylatam',
    twitter_refresh: 10,
    location: {
      lat: 25.750169865569593,
      lng: -100.28673752893064,
      zoom: 15,
      address: null
    },
    email_contact: ''
  }
})

export const mutations = {
  SET_SETTING(state, setting) {
    state.setting = setting
  }
}

export const actions = {
  async get({ commit }) {
    const data = await SettingService.get()
    if (data && data.id) {
      commit('SET_SETTING', data)
    }
  }
}
