let client

export function setClientCatalogSecond(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/transparency/catalogsecond/create', data)
  },
  update: data => {
    return client.$put('/transparency/catalogsecond/update', data)
  },
  get: (catalog, parent, id) => {
    return client.$post(
      '/transparency/catalogsecond/get/' + catalog + '/' + parent + '/' + id
    )
  },
  getByParent: (catalog, parent) => {
    return client.$post(
      '/transparency/catalogsecond/get-by-parent/' + catalog + '/' + parent
    )
  },
  delete: id => {
    return client.$post('/transparency/catalogsecond/delete/' + id)
  },
  confirmingDeletion: data => {
    return client.$post('/transparency/catalogsecond/confirming-deletion', data)
  }
}
