let client

export function setClientStreets(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/urbanism/street/create', data)
  },
  createMulti: data => {
    return client.$post('/urbanism/street/createMulti', data)
  },
  update: data => {
    return client.$put('/urbanism/street/update', data)
  },
  list: data => {
    return client.$post('/urbanism/street/list')
  },
  getByParent: id => {
    return client.$post('/urbanism/street/getbyparent/' + id)
  }
}
