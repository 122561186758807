let client

export function setClientFollower(newclient) {
  client = newclient
}

export default {
  create: (id, data) => {
    return client.$post('/follower/create/' + id, data)
  },
  remove: (id, data) => {
    return client.$post('/follower/remove/' + id, data)
  }
}
