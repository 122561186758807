<template>
  <v-app>
    <app-header />
    <v-content class="app-content">
      <nuxt />
      <NotificationContainer />
      <app-footer />
    </v-content>
  </v-app>
</template>

<script>
import Header from '@/components/public/Header.vue'
import Footer from '@/components/public/Footer.vue'
import NotificationContainer from '@/components/notifications/NotificationContainer.vue'

export default {
  middleware: ['authenticated'],
  name: 'App',
  components: {
    appHeader: Header,
    appFooter: Footer,
    NotificationContainer
  },
  head() {
    return {
      title: this.$store.state.brand.brand.app_name,
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: this.$store.state.brand.brand.favicon
        }
      ],
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$store.state.brand.brand.city_slogan
        },
        {
          hid: 'og_title',
          name: 'og:title',
          content: this.$store.state.brand.brand.app_name
        },
        {
          hid: 'og_site_name',
          name: 'og:site_name',
          content: this.$store.state.brand.brand.app_name
        },
        {
          hid: 'og_type',
          name: 'og:type',
          content: 'website'
        },
        {
          hid: 'og_url',
          name: 'og:url',
          content: process.env.OSCITY_URL
        },
        {
          hid: 'og_image',
          name: 'og:image',
          content: this.$store.state.brand.brand.city_splash_primary
        },
        {
          hid: 'og_description',
          name: 'og:description',
          content: this.$store.state.brand.brand.city_slogan
        },
        {
          hid: 'twitter_card',
          name: 'twitter:card',
          content: 'summary'
        },
        {
          hid: 'twitter_site',
          name: 'twitter:site',
          content: process.env.OSCITY_URL
        },
        {
          hid: 'twitter_title',
          name: 'twitter:title',
          content: this.$store.state.brand.brand.app_name
        },
        {
          hid: 'twitter_description',
          name: 'twitter:description',
          content: this.$store.state.brand.brand.city_slogan
        },
        {
          hid: 'itemprop_name',
          itemprop: 'name',
          content: this.$store.state.brand.brand.app_name
        },
        {
          hid: 'itemprop_description',
          itemprop: 'description',
          content: this.$store.state.brand.brand.city_slogan
        },
        {
          hid: 'itemprop_image',
          itemprop: 'image',
          content: this.$store.state.brand.brand.city_splash_primary
        }
      ]
    }
  }
}
</script>

<style>
.content {
  padding-bottom: 32px !important;
}
.app-content > .v-content__wrap {
  display: flex;
  flex-direction: column;
}
</style>
