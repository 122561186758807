import Vue from 'vue'
import moment from 'moment'
import Vuetify, { VLayout, VList } from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'
import en from 'vuetify/es5/locale/en'

import SettingsIcon from '../components/icons/oscity-settings.vue'
import EditIcon from '../components/icons/oscity-edit.vue'
import CityHallIcon from '../components/icons/oscity-city-hall.vue'
import CloseMenuIcon from '../components/icons/oscity-close-menu.vue'
import DeleteIcon from '../components/icons/oscity-delete.vue'
import AlertIcon from '../components/icons/oscity_alert.vue'
import TimeIcon from '../components/icons/oscity_time.vue'
import CautionIcon from '../components/icons/oscity_caution.vue'
import DefeatedIcon from '../components/icons/oscity_defeated.vue'
import PrivateIcon from '../components/icons/oscity_private.vue'
import BlockchainIcon from '../components/icons/oscity-blockchain.vue'
import CertificatesIcon from '../components/icons/oscity-certificates.vue'
import WorldConnIcon from '../components/icons/oscity-worldconn.vue'
import AnonymousIcon from '../components/icons/anonymous.vue'
import TwitterIcon from '../components/icons/twitter.vue'

export default function({ app }, inject) {
  inject('currentUrl', app.context.env.baseUrl)
  Vue.use(Vuetify, {
    iconfont: 'md',
    icons: {
      oscity_settings: {
        component: SettingsIcon
      },
      oscity_edit: {
        component: EditIcon
      },
      oscity_city_hall: {
        component: CityHallIcon
      },
      oscity_close_menu: {
        component: CloseMenuIcon
      },
      oscity_delete: {
        component: DeleteIcon
      },
      oscity_alert: {
        component: AlertIcon
      },
      oscity_time: {
        component: TimeIcon
      },
      oscity_caution: {
        component: CautionIcon
      },
      oscity_defeated: {
        component: DefeatedIcon
      },
      oscity_private: {
        component: PrivateIcon
      },
      oscity_blockchain: {
        component: BlockchainIcon
      },
      oscity_certificates: {
        component: CertificatesIcon
      },
      oscity_worldconn: {
        component: WorldConnIcon
      },
      anonymous: {
        component: AnonymousIcon
      },
      twitter: {
        component: TwitterIcon
      }
    },
    lang: {
      locales: { es, en },
      current: 'es'
    },
    components: { VLayout, VList },
    options: {
      customProperties: true
    }
  })
}

moment.locale('es')
