let client

export function setClientActivities(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/listener/activities/create', data)
  },
  update: data => {
    return client.$put('/listener/activities/update', data)
  },
  list: () => {
    return client.$post('/listener/activities/list')
  }
}
