let client

export function setClientConformities(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/conformities/create', data)
  },
  update: data => {
    return client.$put('/conformities/update', data)
  },
  list: () => {
    return client.$post('/conformities/list')
  }
}
