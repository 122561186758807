<template>
  <svg class="oscityIcon" viewBox="0 0 59.9 59.77">
    <path
      d="M42.88,44.68c.17,3,0,6.09,0,9.13,0,2.23-1,3.19-3.19,3.2q-12.67,0-25.34,0c-.32,0-.63,0-1-.07V2.65c.34,0,.58,0,.82,0q12.8,0,25.58,0c2.12,0,3.1,1.05,3.11,3.15q0,11.82.11,23.63c0,.41.06.82.11,1.49.87-1,2.1-1.13,2.09-2.51,0-7.8-.06-15.6-.1-23.39A4.81,4.81,0,0,0,42.37.57,7.33,7.33,0,0,0,39.19,0Q23.89,0,8.61,0a5,5,0,0,0-5,4.62C3.45,5.74,3.41,6.86,3.33,8H1.52C.68,8,.05,8.36,0,9.26s.63,1.33,1.5,1.36c.6,0,1.2,0,1.84,0v7.6H1.82c-.94,0-1.8.13-1.81,1.28s.9,1.27,1.8,1.31l1.54.09v7.68c-.76,0-1.45.11-2.13.1-.83,0-1.3.49-1.17,1.17a1.8,1.8,0,0,0,1.16,1.05,8.89,8.89,0,0,0,2.14.16v7.69l-2.11.12A1.17,1.17,0,0,0,0,40.11,1.19,1.19,0,0,0,1.3,41.4c.67,0,1.35,0,2,0V49c-.74,0-1.39,0-2,0A1.21,1.21,0,0,0,0,50.36a1.24,1.24,0,0,0,1.36,1.26c.67,0,1.35,0,2.15,0-.45,3.63.29,6.53,3.77,8.06H40.91c3-1,4.16-2.59,4.21-5.86,0-2.48,0-5,0-7.43,0-1.61,0-3.21,0-5C43.76,42.27,42.78,42.93,42.88,44.68ZM12.11,57a41.63,41.63,0,0,1-4.19,0,2.34,2.34,0,0,1-2.06-2.11c-.18-1-.18-2-.28-3.32a13,13,0,0,1,1.81.54,1.68,1.68,0,0,0,2.28-.39,2.14,2.14,0,0,0,0-2.8,1.67,1.67,0,0,0-2.3-.28,8.52,8.52,0,0,1-1.57.56v-7.8a11.63,11.63,0,0,1,1.57.49A1.7,1.7,0,0,0,9.7,41.4a2.05,2.05,0,0,0,0-2.7,1.66,1.66,0,0,0-2.39-.26,7.42,7.42,0,0,1-1.6.5c0-2.64-.07-5,0-7.41,0-.94.82-.45,1.31-.3s1.14.67,1.53.53c.6-.23,1.36-.83,1.46-1.37a2.78,2.78,0,0,0-.68-2.11c-.31-.34-1.23-.21-1.85-.12a8.65,8.65,0,0,0-1.75.62v-8a8,8,0,0,1,1.61.51A1.65,1.65,0,0,0,9.63,21a2.08,2.08,0,0,0,.09-2.7,1.67,1.67,0,0,0-2.26-.52c-.5.21-1,.38-1.7.64v-7.8A7.61,7.61,0,0,1,7.31,11a1.68,1.68,0,0,0,2.38-.37A2.05,2.05,0,0,0,9.66,8a1.71,1.71,0,0,0-2.4-.34,10.61,10.61,0,0,1-1.69.54,25.89,25.89,0,0,1,.36-3.62A2.37,2.37,0,0,1,8.3,2.65c1.25,0,2.5,0,3.81,0Z"
    />
    <path
      d="M47.88,31.31A1.81,1.81,0,0,0,45,30.87L27.33,45c-.25.2-.47.42-.56.5A15.13,15.13,0,0,1,29.26,48a17.14,17.14,0,0,1,1.17,3.48q6.74-5.37,13.5-10.74c1.9-1.51,3.84-3,5.76-4.43V34.58C49.09,33.49,48.55,32.36,47.88,31.31Z"
    />
    <path
      d="M39.75,22.83c0-1-.64-1.34-1.62-1.33q-6.2.09-12.39.1c-.93,0-1.65.2-1.61,1.29s.76,1.22,1.61,1.22h6.2v0c2.07,0,4.13,0,6.2,0C39.11,24.17,39.76,23.87,39.75,22.83Z"
    />
    <path
      d="M38.24,11.81q-6.32.07-12.63.07c-.86,0-1.45.24-1.48,1.17s.6,1.31,1.5,1.31h6.44c2.06,0,4.12,0,6.19,0,1,0,1.52-.32,1.51-1.27S39.18,11.8,38.24,11.81Z"
    />
    <path
      d="M31.17,33.59c1.82,0,3.65,0,5.47,0A1.13,1.13,0,0,0,38,32.38c0-.88-.4-1.32-1.34-1.31-3.65,0-7.29,0-10.94,0-.89,0-1.56.23-1.55,1.24s.69,1.28,1.57,1.28h5.47Z"
    />
    <path
      d="M21.08,20.57a25.08,25.08,0,0,0-2,2.14,6.59,6.59,0,0,0-1.16-1.19,4.1,4.1,0,0,0-1.35-.27c-.06.5-.34,1.15-.13,1.48a12,12,0,0,0,2,2.27,1.17,1.17,0,0,0,1.14.08,32.8,32.8,0,0,0,3-3.35c.2-.27-.11-.93-.18-1.41C21.93,20.39,21.37,20.32,21.08,20.57Z"
    />
    <path
      d="M18.49,34.7a1,1,0,0,0,.93.1,40.62,40.62,0,0,0,3.16-3.53c.19-.24-.08-1-.33-1.35-.08-.12-.85,0-1.11.21-.73.63-1.36,1.38-2.23,2.3a6.79,6.79,0,0,0-1.1-1.34c-.31-.23-.84-.17-1.27-.25-.08.47-.38,1.09-.19,1.38A17.4,17.4,0,0,0,18.49,34.7Z"
    />
    <path
      d="M22.33,10.69c-.43.08-1,0-1.26.28a26.78,26.78,0,0,0-2,2.16,8,8,0,0,0-1.21-1.25c-.33-.22-1.07-.38-1.19-.22-.26.33-.48,1-.3,1.31a14.39,14.39,0,0,0,2.13,2.48,1.2,1.2,0,0,0,1.16,0,34.78,34.78,0,0,0,2.93-3.38C22.74,11.81,22.41,11.16,22.33,10.69Z"
    />
    <path
      d="M22.81,52.46c-.28.61,0,1,.7.83,1.26-.24,2.5-.54,4.16-.9l-2.35-4-.52,0C24.13,49.8,23.43,51.11,22.81,52.46Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'EditIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
