let client

export function setClientObras(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/urbanism/obras/create', data)
  },
  update: data => {
    return client.$put('/urbanism/obras/update', data)
  },
  get: id => {
    return client.$post('/urbanism/obras/get/' + id)
  },
  list: data => {
    return client.$post('/urbanism/obras/list', data)
  },
  delete: urlsafe => {
    return client.$post('/urbanism/obras/delete/' + urlsafe)
  }
}
