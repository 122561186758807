import Vue from 'vue'

export default function() {
  Vue.filter('formatNumber', function(num, prefix) {
    const result = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    if (prefix === '$') {
      return '$' + result
    } else if (prefix === '%') {
      return result + '%'
    } else {
      return result
    }
  })
}
