let client

export function setClientSocialNetwork(newclient) {
  client = newclient
}

export default {
  twitter: data => {
    return client.$post('/social/twitter/', data)
  }
}
