let client

export function setClientSubcategory(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/subcategory/create', data)
  },
  update: data => {
    return client.$post('/subcategory/update', data)
  },
  delete: data => {
    return client.$post('/subcategory/delete', data)
  },
  get: (parent, id) => {
    return client.$post('/subcategory/getbyid/' + parent + '/' + id)
  },
  getByParent: id => {
    return client.$post('/subcategory/getbyparent/' + id)
  },
  confirmingDeletion: data => {
    return client.$post('/subcategory/confirmingDeletion', data)
  },
  list: data => {
    return client.$post('/subcategory/list')
  },
  listByParentList: data => {
    return client.$post('/subcategory/listByParentList', data)
  },
  listByIdList: data => {
    return client.$post('/subcategory/listByIdList', data)
  }
}
