let client

export function setClientMunicipios(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/listener/municipios/create', data)
  },
  update: data => {
    return client.$put('/listener/municipios/update', data)
  },
  list: data => {
    return client.$post('/listener/municipios/list')
  }
}
