import Vue from 'vue'

export const state = () => ({
  locale: 'es',
  isHomeTop: false,
  currentUrl: ''
})

export const mutations = {
  SET_LOCALE(state, locale) {
    state.locale = locale
  },
  SET_HOME_TOP(state, isTop) {
    state.isHomeTop = isTop
  },
  SET_HOST(state, host) {
    state.currentUrl = host
  }
}

export const actions = {
  changeLocale({ commit }, locale) {
    commit('SET_LOCALE', locale)
  },
  async nuxtServerInit({ commit, dispatch, rootState }, { req }) {
    commit('SET_HOST', req.headers.host)
    await Promise.all([
      dispatch('brand/get'),
      dispatch('menu/get'),
      dispatch('module/get'),
      dispatch('setting/get')
    ])
    if (
      rootState.brand &&
      rootState.brand.brand &&
      rootState.brand.brand.primary_color
    ) {
      Vue.prototype.$vuetify.theme.primary = rootState.brand.brand.primary_color
    }
    if (
      rootState.brand &&
      rootState.brand.brand &&
      rootState.brand.brand.primary_color
    ) {
      Vue.prototype.$vuetify.theme.secondary =
        rootState.brand.brand.secondary_color
    }
    if (
      rootState.brand &&
      rootState.brand.brand &&
      rootState.brand.brand.additional_color
    ) {
      Vue.prototype.$vuetify.theme.accent =
        rootState.brand.brand.additional_color
    }
  }
}
