<template>
  <svg class="oscityIcon" viewBox="0 0 147.7 78.6">
    <g>
      <g>
        <rect x="54.9" width="92.8" height="8" />
        <rect x="54.9" y="32.8" width="92.8" height="8" />
        <rect x="54.9" y="65.7" width="92.8" height="8" />
      </g>
      <path
        d="M28.1,67.4c-1,0-1.9-0.4-2.7-1.1L1.1,42C0.4,41.3,0,40.3,0,39.3c0-1,0.4-2,1.1-2.7l24.4-24.4
        c1.5-1.5,3.9-1.5,5.3,0c1.5,1.5,1.5,3.9,0,5.3L9.1,39.3L30.8,61c1.5,1.5,1.5,3.9,0,5.3C30,67.1,29.1,67.4,28.1,67.4z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SettingsIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
