let client

export function setClientInitiative(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/transparency/initiatives/create', data)
  },
  update: data => {
    return client.$put('/transparency/initiatives/update', data)
  },
  get: id => {
    return client.$post('/transparency/initiatives/get/' + id)
  },
  list: data => {
    return client.$post('/transparency/initiatives/list', data)
  },
  delete: urlsafe => {
    return client.$post('/transparency/initiatives/delete/' + urlsafe)
  },
  changeStatus: data => {
    return client.$post('/transparency/initiatives/change-status', data)
  },
  deleteByStatus: urlsafe => {
    return client.$post('/transparency/initiatives/delete-by-status/' + urlsafe)
  }
}
