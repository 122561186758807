const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['certificates'] = require('../middleware/certificates.js')
middleware['certificates'] = middleware['certificates'].default || middleware['certificates']

middleware['ejecutor'] = require('../middleware/ejecutor.js')
middleware['ejecutor'] = middleware['ejecutor'].default || middleware['ejecutor']

middleware['listener'] = require('../middleware/listener.js')
middleware['listener'] = middleware['listener'].default || middleware['listener']

middleware['neighborhood'] = require('../middleware/neighborhood.js')
middleware['neighborhood'] = middleware['neighborhood'].default || middleware['neighborhood']

middleware['operator'] = require('../middleware/operator.js')
middleware['operator'] = middleware['operator'].default || middleware['operator']

middleware['transparency'] = require('../middleware/transparency.js')
middleware['transparency'] = middleware['transparency'].default || middleware['transparency']

middleware['urbanism'] = require('../middleware/urbanism.js')
middleware['urbanism'] = middleware['urbanism'].default || middleware['urbanism']

export default middleware
