<template>
  <svg width="21px" height="21px" class="oscityIcon" viewBox="0 0 16.17 14">
    <g>
      <path
        d="M-5.8,5.3c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.1-0.4-0.4-0.4h-0.1c-0.9,0-1.7,0-2.7,0c-0.5,0-2.4,0-2.9,0
        c-0.1,0.1-0.3,0.2-0.3,0.3c-0.1,0.3,0.1,0.5,0.4,0.5c0.8,0,2.8,0,3.5,0l0,0c0.5,0,0.9,0,1.4,0C-6.2,5.3-6.1,5.3-5.8,5.3z"
      />
      <path d="M-7.5,9.9L-7.5,9.9C-7.5,9.9-7.1,9.9-7.5,9.9z" />
      <path d="M-7.7,9.1C-7.5,9.1-7.8,9.1-7.7,9.1L-7.7,9.1z" />
      <path
        d="M-7.4,9.9c0.2,0,0.3-0.1,0.4-0.2c0.1-0.3-0.1-0.5-0.4-0.5c-0.2,0-0.2,0-0.3,0c0,0-0.1,0-0.2,0s-0.3,0-0.4,0
        c-0.6,0-2.5,0-3.1,0c-0.3,0-0.5,0.2-0.4,0.5c0,0.1,0.2,0.2,0.4,0.2C-10.4,9.9-8.5,9.9-7.4,9.9C-7.6,9.9-7.6,9.9-7.4,9.9z"
      />
      <path
        d="M-6.7,7.4c0.1-0.3-0.1-0.5-0.4-0.5c-0.6,0-1,0-1.6,0c-0.6,0-2.1,0-2.7,0c-0.2-0.1-0.4,0-0.4,0.2
        c-0.1,0.3,0.1,0.5,0.4,0.5c0.6,0,2.1,0,2.7,0c0.6,0,1,0,1.6,0C-6.9,7.6-6.8,7.5-6.7,7.4z"
      />
      <path
        d="M-2.7,10.4C-2.3,10-2,9.5-2,8.9c0-1.1-0.9-2.1-2.1-2.1s-2.1,0.9-2.1,2c0,0.6,0.3,1.1,0.7,1.5
        c-1.2,0.6-2.1,1.8-2.1,3.3c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4c0-1.5,1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6
        c0,0.2,0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4C-0.6,12.1-1.4,10.9-2.7,10.4z M-4.1,7.6c0.7,0,1.2,0.5,1.2,1.2S-3.4,10-4.1,10
        s-1.2-0.5-1.2-1.2S-4.8,7.6-4.1,7.6z"
      />
      <path
        d="M-8.2,11.4c-0.3,0-0.3,0-0.7,0c-1.2,0-2.4,0-3.6,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.1,0-6.5,0-7.4
        c0-0.8,0-1.6,0-2.5c0-0.3,0.1-0.4,0.4-0.4c1.6,0,3.2,0,4.8,0h0.1c0,0.1,0,0.1,0,0.1c0,0.6,0,1.1,0,1.7c0,0.7,0.5,1.2,1.2,1.2
        c0.6,0,1.1,0,1.7,0h0.1c0,0.1,0,0.1,0,0.2c0,0.8,0,1,0,1.8l0,0c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4l0,0c0-1,0-1.3,0-2.2
        c0-0.2,0-0.3-0.2-0.4c-1-1-1.9-1.9-2.9-2.9C-6.9,0.1-7,0.1-7.1,0c-1.8,0-3.7,0-5.5,0c0,0,0,0-0.1,0c-0.5,0.1-0.8,0.5-0.9,1
        c0,0.3,0,0.6,0,1c0,0.6,0,1.1,0,1.7c0,0.7,0,7.2,0,7.3c0,0.7,0.5,1.2,1.2,1.2c1.5,0,2.7,0,4.2,0c0.2,0,0.4-0.2,0.4-0.4
        C-7.8,11.6-8,11.4-8.2,11.4z M-6.7,1.3C-6.2,1.9-5.6,2.4-5,3c-0.3,0-0.5,0-0.7,0C-6,3-6.2,3-6.4,3c-0.2,0-0.4-0.1-0.4-0.3
        C-6.8,2.2-6.8,1.8-6.7,1.3C-6.8,1.3-6.8,1.3-6.7,1.3z"
      />
    </g>
    <g>
      <path
        d="M97.6,10.4c1.4,0,2.7-1.4,2.7-2.7c0-1.4-0.7-2.7-2.7-2.7H97c-6.1,0-11.5,0-18.3,0c-3.4,0-16.2,0-19.6,0
        c-0.7,0.7-2,1.4-2,2c-0.7,2,0.7,3.4,2.7,3.4c5.4,0,19,0,23.7,0l0,0c3.4,0,6.1,0,9.5,0C94.9,10.4,95.6,10.4,97.6,10.4z"
      />
      <path
        d="M59.7,9c-0.6,0-1.1-0.2-1.3-0.5c-0.2-0.3-0.1-0.7,0-1.1l0-0.1c0.2-0.2,0.5-0.4,0.7-0.5
        c0.2-0.1,0.3-0.3,0.5-0.4h38.1c0.8,0,1.3,0.2,1.3,1.3C99,8.3,98.2,9,97.6,9H59.7z"
      />
      <path d="M86.1,41.5L86.1,41.5C86.1,41.5,88.8,41.5,86.1,41.5z" />
      <path d="M86.1,41.5L86.1,41.5C86.1,41.5,88.8,41.5,86.1,41.5z" />
      <path d="M84.8,36.1C86.1,36.1,84.1,36.1,84.8,36.1L84.8,36.1z" />
      <path d="M84.8,36.1C86.1,36.1,84.1,36.1,84.8,36.1L84.8,36.1z" />
      <path
        d="M86.8,41.5c1.4,0,2-0.7,2.7-1.4c0.7-2-0.7-3.4-2.7-3.4c-1.4,0-1.4,0-2,0c0,0-0.7,0-1.4,0s-2,0-2.7,0
        c-4.1,0-16.9,0-21,0c-2,0-3.4,1.4-2.7,3.4c0,0.7,1.4,1.4,2.7,1.4c6.8,0,19.6,0,26.4,0C85.5,41.5,85.5,41.5,86.8,41.5z"
      />
      <path
        d="M59.7,40.1c-0.6,0-1.1-0.2-1.4-0.3l0-0.1c-0.1-0.3-0.2-0.7,0-1.1c0.2-0.3,0.7-0.5,1.3-0.5h27.1
        c0.6,0,1.1,0.2,1.3,0.5c0.2,0.2,0.2,0.5,0.1,0.8c-0.5,0.5-0.8,0.7-1.5,0.7H59.7z"
      />
      <path
        d="M91.6,24.6c0.7-2-0.7-3.4-2.7-3.4c-4.1,0-6.8,0-10.8,0c-4.1,0-14.2,0-18.3,0c-1.4-0.7-2.7,0-2.7,1.4
        c-0.7,2,0.7,3.4,2.7,3.4c4.1,0,14.2,0,18.3,0c4.1,0,6.8,0,10.8,0C90.2,26,90.9,25.3,91.6,24.6z"
      />
      <path
        d="M59.7,24.6c-0.6,0-1.1-0.2-1.3-0.5c-0.2-0.3-0.1-0.7,0-1.1l0.1-0.2v-0.2c0-0.1,0-0.2,0.2-0.2
        c0.1,0,0.2,0,0.4,0.1l0.3,0.1h29.4c0.6,0,1.1,0.2,1.3,0.5c0.2,0.2,0.2,0.5,0.1,0.8c-0.5,0.5-0.8,0.7-1.5,0.7H59.7z"
      />
      <path
        d="M118.6,44.9c2.7-2.7,4.7-6.1,4.7-10.2c0-7.4-6.1-14.2-14.2-14.2s-14.2,6.1-14.2,13.5c0,4.1,2,7.4,4.7,10.2
        c-8.1,4.1-14.2,12.2-14.2,22.3c0,1.4,1.4,2.7,2.7,2.7c1.4,0,2.7-1.4,2.7-2.7C90.9,56.4,99,49,108.5,49s17.6,8.1,17.6,17.6
        c0,1.4,1.4,2.7,2.7,2.7s2.7-1.4,2.7-2.7C132.9,56.4,127.4,48.3,118.6,44.9z M109.2,26c4.7,0,8.1,3.4,8.1,8.1s-3.4,8.1-8.1,8.1
        c-4.7,0-8.1-3.4-8.1-8.1S104.4,26,109.2,26z"
      />
      <path
        d="M128.8,67.9c-0.6,0-1.3-0.7-1.3-1.3c0-10.3-8.7-19-19-19c-10.7,0-19,8.3-19,19c0,0.6-0.7,1.3-1.3,1.3
        s-1.3-0.7-1.3-1.3c0-8.9,5.2-16.9,13.4-21.1l1.7-0.9l-1.4-1.4c-2.9-2.9-4.3-6-4.3-9.2c0-6.8,5.6-12.1,12.8-12.1
        c7.1,0,12.8,5.8,12.8,12.8c0,3.2-1.5,6.3-4.3,9.2l-1.5,1.5l2,0.8c8.6,3.3,13.2,11,12,20.2l0,0.1v0.1
        C130.1,67.2,129.4,67.9,128.8,67.9z M109.2,24.6c-5.5,0-9.5,4-9.5,9.5c0,5.5,4,9.5,9.5,9.5s9.5-4,9.5-9.5
        C118.7,28.6,114.7,24.6,109.2,24.6z"
      />
      <path
        d="M81.4,51.7c-2,0-2,0-4.7,0c-8.1,0-16.2,0-24.4,0c-2,0-2.7-0.7-2.7-2.7c0-0.7,0-44,0-50.1c0-5.4,0-10.8,0-16.9
        c0-2,0.7-2.7,2.7-2.7c10.8,0,21.7,0,32.5,0h0.7c0,0.7,0,0.7,0,0.7c0,4.1,0,7.4,0,11.5c0,4.7,3.4,8.1,8.1,8.1c4.1,0,7.4,0,11.5,0
        h0.7c0,0.7,0,0.7,0,1.4c0,5.4,0,6.8,0,12.2l0,0c0,1.4,1.4,2.7,2.7,2.7c1.4,0,2.7-1.4,2.7-2.7l0,0c0-6.8,0-8.8,0-14.9
        c0-1.4,0-2-1.4-2.7c-6.8-6.8-12.9-12.9-19.6-19.6c0-0.7-0.7-0.7-1.4-1.4c-12.2,0-25,0-37.2,0c0,0,0,0-0.7,0
        c-3.4,0.7-5.4,3.4-6.1,6.8c0,2,0,4.1,0,6.8c0,4.1,0,7.4,0,11.5c0,4.7,0,48.7,0,49.4c0,4.7,3.4,8.1,8.1,8.1c10.2,0,18.3,0,28.4,0
        c1.4,0,2.7-1.4,2.7-2.7S82.8,51.7,81.4,51.7z M91.6-16.7c3.4,4.1,7.4,7.4,11.5,11.5c-2,0-3.4,0-4.7,0c-2,0-3.4,0-4.7,0
        s-2.7-0.7-2.7-2C90.9-10.6,90.9-13.3,91.6-16.7C90.9-16.7,90.9-16.7,91.6-16.7z"
      />
      <path
        d="M53,55.7c-4,0-6.7-2.8-6.7-6.7v-67.6c0.4-1.8,1.5-4.8,4.8-5.5h37.3c0.2,0.1,0.3,0.2,0.5,0.3v0.2L109-3.4
        l0.2,0.1c0.6,0.3,0.6,0.3,0.6,1.5v14.9c0,0.6-0.7,1.3-1.3,1.3s-1.3-0.7-1.3-1.3V-1.8H93.6c-4,0-6.7-2.8-6.7-6.7v-13.6H52.3
        c-2.8,0-4.1,1.3-4.1,4.1v67c0,2.8,1.3,4.1,4.1,4.1h29.1c0.6,0,1.3,0.7,1.3,1.3c0,0.6-0.7,1.3-1.3,1.3H53z M90.2-17
        c-0.7,3.5-0.7,6.3-0.7,9.8c0,2,1.7,3.4,4.1,3.4h12.9l-2.4-2.4c-1.4-1.4-2.8-2.7-4.1-4c-2.6-2.5-5.1-4.8-7.3-7.4l-1.9-2.2L90.2-17z"
      />
    </g>
    <!-- <g id="I1dGoq.tif">
      <image
        style="overflow:visible;"
        width="799"
        height="137"
        xlink:href="D9292B8CBC57D63C.png"
        transform="matrix(0.2097 0 0 0.2097 -144.7076 -31.6762)"
      ></image>
    </g> -->
    <g>
      <path
        d="M2.1,5C2,5,1.9,5,1.9,5c0,0,0-0.1,0-0.2l0,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1h5.8C8,4.6,8,4.7,8,4.8
        C8,4.9,7.9,5,7.8,5H2.1z"
      />
      <path
        d="M1.8,9.9c-0.1,0-0.2,0-0.2-0.1l0,0c0-0.1,0-0.1,0-0.2c0,0,0.1-0.1,0.2-0.1h4.3c0.1,0,0.2,0,0.2,0.1
        c0,0,0,0.1,0,0.1C6.3,9.9,6.3,9.9,6.2,9.9H1.8z"
      />
      <path
        d="M2.1,7.4c-0.1,0-0.2,0-0.2-0.1c0,0,0-0.1,0-0.2l0,0v0c0,0,0,0,0,0c0,0,0,0,0.1,0l0,0h4.5c0.1,0,0.2,0,0.2,0.1
        c0,0,0,0.1,0,0.1C6.6,7.4,6.6,7.4,6.5,7.4H2.1z"
      />
      <path
        d="M12.6,14c-0.1,0-0.2-0.1-0.2-0.2c0-1.6-1.3-2.9-2.9-2.9c-1.6,0-2.9,1.3-2.9,2.9c0,0.1-0.1,0.2-0.2,0.2
        c-0.1,0-0.2-0.1-0.2-0.2c0-1.3,0.8-2.6,2-3.2l0.3-0.1l-0.2-0.2C7.9,9.8,7.6,9.3,7.6,8.9c0-1,0.9-1.8,2-1.8c1.1,0,2,0.9,2,2
        c0,0.5-0.2,1-0.7,1.4l-0.2,0.2l0.3,0.1c1.3,0.5,2,1.7,1.8,3.1l0,0v0C12.8,13.9,12.7,14,12.6,14z M9.6,7.4C8.8,7.4,8.1,8,8.1,8.9
        c0,0.8,0.6,1.4,1.4,1.4S11,9.7,11,8.9C11,8,10.4,7.4,9.6,7.4z"
      />
      <path
        d="M1,12.1c-0.6,0-1-0.4-1-1V0.8C0.1,0.6,0.2,0.1,0.8,0h5.7c0,0,0.1,0,0.1,0v0l3.1,3.1l0,0c0.1,0,0.1,0,0.1,0.2
        v2.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2-0.1-0.2-0.2V3.4H7.2c-0.6,0-1-0.4-1-1V0.3H0.9c-0.4,0-0.6,0.2-0.6,0.6v10.2
        c0,0.4,0.2,0.6,0.6,0.6h4.4c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,0.2-0.2,0.2H1z M6.7,1.1C6.6,1.6,6.6,2,6.6,2.6c0,0.3,0.3,0.5,0.6,0.5
        h2L8.8,2.7C8.6,2.5,8.4,2.3,8.2,2.1C7.8,1.7,7.4,1.4,7.1,1L6.8,0.6L6.7,1.1z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PrivateIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
