import ModuleService from '@/services/ModuleService'

export const mutations = {
  SET_MODULES(state, modules) {
    state.modules = modules
  }
}

export const actions = {
  async get({ commit }) {
    const { list } = await ModuleService.list()
    commit('SET_MODULES', list)
  }
}
