let client

export function setClientInstitution(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/listener/institutions/create', data)
  },
  update: data => {
    return client.$put('/listener/institutions/update', data)
  },
  list: data => {
    return client.$post('/listener/institutions/list')
  }
}
