let client

export function setClientAi(newclient) {
  client = newclient
}

export default {
  createImage: data => {
    return client.$post('/ai/createImage', data)
  },
  updateImage: data => {
    return client.$post('/ai/updateImage', data)
  },
  getImage: id => {
    return client.$post('/ai/getImage/' + id)
  },
  getImages: data => {
    return client.$post('/ai/getImages', data)
  },
  createText: data => {
    return client.$post('/ai/createText', data)
  },
  updateText: data => {
    return client.$post('/ai/updateText', data)
  },
  getText: id => {
    return client.$post('/ai/getText/' + id)
  },
  getTexts: data => {
    return client.$post('/ai/getTexts', data)
  },
  deleteImages: id => {
    return client.post('/ai/delete-images/' + id)
  },
  delete: id => {
    return client.post('/ai/delete-ai/' + id)
  }
}
