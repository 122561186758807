require('dotenv').config()

/* eslint-disable */
const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain:
    process.env.FIREBASE_AUTH_DOMAIN || 'upsocmp.firebaseio.com',
  databaseURL:
    process.env.FIREBASE_DATABASE_URL ||
    'https://upsocmp.firebaseio.com',
  projectId: process.env.FIREBASE_PROJECT_ID || 'upsocmp',
  storageBucket:
    process.env.FIREBASE_STORAGE_BUCKET || 'upsocmp.appspot.com',
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || '788864199337',
  appId: process.env.FIREBASE_APP_ID || '1:788864199337:web:9af1782e455026e1a6d7b8'
}

export default config
