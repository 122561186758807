let client

export function setClientCategory(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/category/create', data)
  },
  update: data => {
    return client.$post('/category/update', data)
  },
  delete: id => {
    return client.$post('/category/delete/' + id)
  },
  get: () => {
    return client.$post('/category/get')
  },
  getUnused: data => {
    return client.$post('/category/get-unused', data)
  },
  getById: id => {
    return client.$post('/category/getbyid/' + id)
  },
  getInCharge: () => {
    return client.$post('/category/get-in-charge')
  },
  getGraph: () => {
    return client.$post('/category/graph')
  }
}
