<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 157.91 191.04"
  >
    <path
      class="oscityIcon"
      d="M156.67,41c0-.08-.07-.16-.1-.23a3.46,3.46,0,0,0-.42-.63,1.31,1.31,0,0,0-.11-.12,2.35,2.35,0,0,0-.65-.47l-75.24-38a2.67,2.67,0,0,0-2.4,0l-75.24,38a2.65,2.65,0,0,0,0,4.74L76.3,81.6v85.79L6.37,132.06V61.53a2.66,2.66,0,0,0-5.32,0V133.7a2.45,2.45,0,0,0,.08.37,2.64,2.64,0,0,0,.11.56,2.76,2.76,0,0,0,.25.44,2.79,2.79,0,0,0,.28.42,3.75,3.75,0,0,0,.44.35,3.4,3.4,0,0,0,.3.23l75.24,38,.08,0a2.68,2.68,0,0,0,1.12.27,2.25,2.25,0,0,0,.58-.08,2.76,2.76,0,0,0,.55-.19l.07,0,15.49-7.83v20.85a2.66,2.66,0,0,0,2.66,2.66h44.9a2.66,2.66,0,0,0,2.66-2.66v-46a1.42,1.42,0,0,0,0-.2l9.57-4.84a2.37,2.37,0,0,0,.3-.23,3.17,3.17,0,0,0,.45-.35,3.83,3.83,0,0,0,.28-.42,2.76,2.76,0,0,0,.25-.44,3.47,3.47,0,0,0,.11-.56,2.41,2.41,0,0,0,.07-.37V42A2.79,2.79,0,0,0,156.67,41Zm-47.85,86.91v-3.81a12.22,12.22,0,0,1,.23-2.27,11.94,11.94,0,0,1,11.7-9.66,11.82,11.82,0,0,1,5.08,1.18,12,12,0,0,1,4.49,3.69,11.82,11.82,0,0,1,2.36,7.06v14.32H108.82ZM101,163.58V143.77h39.58v40.68H101Zm39.7-26v.89H138V124.13a17.25,17.25,0,0,0-34.5,0v14.32H98.3a2.66,2.66,0,0,0-2.66,2.66v19.2l-14,7.08V80a2,2,0,0,0-.07-.37,3.27,3.27,0,0,0-.11-.56,2.38,2.38,0,0,0-.25-.44,1.68,1.68,0,0,0-.73-.77,1.76,1.76,0,0,0-.3-.24L9.61,42,79,6.91,148.3,42,90,71.41a2.66,2.66,0,0,0,2.4,4.75l59.15-29.89v85.79Z"
    />
    <path
      class="oscityIcon"
      d="M21.36,113.93l37.54,19a2.74,2.74,0,0,0,1.2.28,2.66,2.66,0,0,0,1.2-5l-37.54-19a2.66,2.66,0,0,0-2.4,4.75Z"
    />
    <path
      class="oscityIcon"
      d="M21.36,95l37.54,19a2.74,2.74,0,0,0,1.2.28,2.65,2.65,0,0,0,1.2-5l-37.54-19A2.66,2.66,0,1,0,21.36,95Z"
    />
    <path
      class="oscityIcon"
      d="M21.36,76,58.9,95a2.62,2.62,0,0,0,1.2.29,2.66,2.66,0,0,0,1.2-5l-37.54-19A2.66,2.66,0,1,0,21.36,76Z"
    />
    <path
      class="oscityIcon"
      d="M95.43,131.72a2.66,2.66,0,0,0,2.38,1.46,2.74,2.74,0,0,0,1.2-.28l1.83-.93v-6l-4.23,2.14A2.65,2.65,0,0,0,95.43,131.72Z"
    />
    <path
      class="oscityIcon"
      d="M134.14,109.18l-.18.1a19.66,19.66,0,0,1,3.43,4,2.66,2.66,0,0,0-3.25-4.06Z"
    />
    <path
      class="oscityIcon"
      d="M122.55,115l-10.14,5.13a9.08,9.08,0,0,0-.93,4v2.46l16.42-8.29A9.27,9.27,0,0,0,122.55,115Z"
    />
    <path
      class="oscityIcon"
      d="M134.14,90.22l-37.53,19a2.65,2.65,0,0,0,1.2,5,2.74,2.74,0,0,0,1.2-.28l7.7-3.89a19.87,19.87,0,0,1,11-5.56L136.54,95a2.66,2.66,0,1,0-2.4-4.74Z"
    />
    <path
      class="oscityIcon"
      d="M134.14,71.25l-37.53,19a2.66,2.66,0,0,0,1.2,5A2.62,2.62,0,0,0,99,95l37.53-19a2.66,2.66,0,1,0-2.4-4.74Z"
    />
    <path
      class="oscityIcon"
      d="M85.68,18.28a2.65,2.65,0,0,0-3.57-1.18l-40,20.2a2.66,2.66,0,0,0,2.4,4.75l40-20.2A2.66,2.66,0,0,0,85.68,18.28Z"
    />
    <path
      class="oscityIcon"
      d="M101.31,27.87a2.65,2.65,0,0,0-3.57-1.17l-40,20.19a2.65,2.65,0,0,0,1.2,5,2.74,2.74,0,0,0,1.2-.28l40-20.2A2.65,2.65,0,0,0,101.31,27.87Z"
    />
    <path
      class="oscityIcon"
      d="M117,37.46a2.66,2.66,0,0,0-3.58-1.17l-40,20.2a2.66,2.66,0,0,0,1.2,5,2.59,2.59,0,0,0,1.2-.29l40-20.19A2.67,2.67,0,0,0,117,37.46Z"
    />
    <path
      class="oscityIcon"
      d="M120.75,151.88a2.66,2.66,0,0,0-2.66,2.66v19.6a2.66,2.66,0,0,0,5.32,0v-19.6a2.68,2.68,0,0,0-2.66-2.66Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CertificatesIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
