/* eslint-disable */
const environment = process.env.NODE_ENV

if (environment === 'production') {
  window.addEventListener('DOMContentLoaded', function() {
    const errorHandler = new StackdriverErrorReporter()
    errorHandler.start({
      key: 'AIzaSyDzYFS8y8o5IchqY4joK6nUrG8MwQdJnmI',
      projectId: 'playground-gcp-201118',
      service: 'nuxtjs' // (optional)
      // version: '<my-service-version>' // (optional)
      // reportUncaughtExceptions: false // (optional) Set to false to stop reporting unhandled exceptions.
      // reportUnhandledPromiseRejections: false // (optional) Set to false to stop reporting unhandled promise rejections.
      // disabled: true // (optional) Set to true to not report errors when calling report(), this can be used when developing locally.
      // context: {user: 'user1'} // (optional) You can set the user later using setUser()
    })
  })
}
