<template>
  <svg width="21px" height="21px" class="oscityIcon" viewBox="0 0 16.17 14">
    <path
      d="M12.12,0H4L0,7l4,7h8.08l4.05-7ZM7.86,11a1.29,1.29,0,0,1-1,.47h0a1.29,1.29,0,0,1-.94-.41L4.15,9.21A1.3,1.3,0,1,1,6,7.42l.77.81,3.68-4.38a1.3,1.3,0,0,1,2,1.68Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'TimeIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
