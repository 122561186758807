<template>
  <v-container v-if="notifications && notifications.length > 0" grid-list-md>
    <v-layout row wrap>
      <v-flex class="notification-container" xs12 md6>
        <NotificationBar
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import NotificationBar from '@/components/notifications/NotificationBar.vue'

export default {
  components: {
    NotificationBar
  },
  computed: mapState('notification', ['notifications'])
}
</script>

<style lang="stylus" scoped>
.notification-container {
  right 0
  bottom 0
  position fixed
  width 100%
  z-index 5
}
</style>
