let client

export function setClientCatalogFirst(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/transparency/catalogfirst/create', data)
  },
  update: data => {
    return client.$put('/transparency/catalogfirst/update', data)
  },
  get: (parent, id) => {
    return client.$post('/transparency/catalogfirst/get/' + parent + '/' + id)
  },
  getByParent: id => {
    return client.$post('/transparency/catalogfirst/get-by-parent/' + id)
  },
  getByParentName: data => {
    return client.$post('/transparency/catalogfirst/get-by-parent-name', data)
  },
  delete: id => {
    return client.$post('/transparency/catalogfirst/delete/' + id)
  },
  confirmingDeletion: data => {
    return client.$post('/transparency/catalogfirst/confirming-deletion', data)
  }
}
