let client

export function setClientSetting(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/setting/create', data)
  },
  update: data => {
    return client.$post('/setting/update/', data)
  },
  get: () => {
    return client.$post('/setting/get')
  }
}
