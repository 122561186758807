export default (context, inject) => {
  inject('cartodb', {
    config: {
      city_user: context.env.CARTODB_USER,
      city_table_name: context.env.CARTODB_TABLE_NAME,
      city_ageb_poly: context.env.CARTODB_AGEB_POLY,
      city_comunidades: context.env.CARTODB_COMMUNITIES,
      city_mun_table_name: context.env.CARTODB_MUN_TABLE_NAME,
      city_pois_table_name: context.env.CARTODB_POIS_TABLE_NAME,
      city_polygon_cve_ent: context.env.CARTODB_POLYGON_CVE_ENT
    },
    execute: query => {
      let citySql
      if (!citySql) {
        citySql = new cartodb.SQL({
          sql_api_template: 'https://maps.os.city/user/{user}',
          host: `https://maps.os.city/user/${context.env.CARTODB_USER}`,
          user: context.env.CARTODB_USER
        })
      }
      return citySql.execute(query)
    }
  })
}
