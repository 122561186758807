<template>
  <div class="navbar">
    <client-only>
      <Sidebar v-model="drawer" @close-drawer="drawer = $event" />
      <v-toolbar
        :dark="$store.state.isHomeTop || $store.state.brand.brand.dark_mode"
        :clipped-left="clipped"
        :color="
          $store.state.isHomeTop
            ? 'rgba(0,0,0,0.3)'
            : $store.state.brand.brand.dark_mode
            ? 'rgba(0,0,0,0.5)'
            : 'white'
        "
        :style="{ boxShadow: $store.state.isHomeTop ? 'unset' : '' }"
        app
        dense
        extended
        extension-height="2"
        height="56"
      >
        <!-- SideBar -->
        <v-flex
          v-show="$auth.loggedIn || $vuetify.breakpoint.smAndDown"
          xs6
          md4
        >
          <v-toolbar-side-icon
            data-cy="toggle-sidebar"
            @click.stop="drawer = !drawer"
          ></v-toolbar-side-icon>
        </v-flex>
        <!-- LOGO -->
        <v-flex
          :class="{
            md4: ismd4,
            md6: ismd4 && !$auth.loggedIn,
            lg6: !ismd4 && $auth.loggedIn
          }"
          xs6
          class="align-center"
        >
          <nuxt-link
            :class="{
              'justify-end': $vuetify.breakpoint.smAndDown,
              'justify-center': !$vuetify.breakpoint.smAndDown && $auth.loggedIn
            }"
            to="/"
            class="d-flex"
          >
            <img
              :src="$store.state.brand.brand.logo"
              height="38px"
              alt="Logo"
              class="text-xs-center"
              style="flex:none !important"
            />
          </nuxt-link>
        </v-flex>
        <!-- Sesion -->
        <v-flex
          v-show="!$vuetify.breakpoint.smAndDown"
          :class="{
            md4: ismd4,
            md6: ismd4 && !$auth.loggedIn,
            lg6: !ismd4 && $auth.loggedIn
          }"
          xs6
        >
          <v-toolbar-items class="justify-end">
            <div
              v-if="!$auth.loggedIn && $vuetify.breakpoint.mdAndUp"
              style="height:34px"
            >
              <v-btn flat>
                <a
                  href="/faq"
                  style="text-decoration: none"
                  :style="{
                    color: $store.state.brand.brand.dark_mode ? 'white' : ''
                  }"
                  data-cy="btn-faq"
                >
                  Conoce la experiencia
                </a>
              </v-btn>
              <!-- <v-btn
                :style="{ color: $store.state.isHomeTop ? 'white' : '' }"
                data-cy="dialog-login"
                flat
                :outline="$store.state.brand.brand.dark_mode"
                @click.native.stop="dialogLogin = true"
              >
                {{ $t('btnsignin') }}
              </v-btn> -->
              <!-- <v-btn
                data-cy="dialog-register"
                class="primary"
                @click.native.stop="dialogRegister = true"
              >
                {{ $t('btnsignup') }}
              </v-btn> -->
            </div>
            <v-list v-else class="my-0 py-0 profile-header">
              <v-list-tile
                v-if="$auth.loggedIn && $auth.user.first_name"
                :class="{ 'pr-0': $vuetify.breakpoint.smAndDown }"
                avatar
                to="/profile"
              >
                <v-list-tile-avatar
                  :class="{ 'avatar-width': $vuetify.breakpoint.smAndDown }"
                  color="primary"
                >
                  <img
                    v-if="$auth.user.picture"
                    :src="$auth.user.picture"
                    alt="Avatar"
                  />
                  <span
                    v-else-if="$auth.user.first_name"
                    class="white--text headline"
                  >
                    {{ $auth.user.first_name.charAt(0) }}
                  </span>
                </v-list-tile-avatar>
                <!-- <v-list-tile-content class="hidden-sm-and-down">
                  <v-list-tile-title>
                    {{ $auth.user.first_name }}
                  </v-list-tile-title>
                  <v-list-tile-sub-title>
                    {{ $auth.user.role ? $auth.user.role.name : '' }}
                  </v-list-tile-sub-title>
                </v-list-tile-content> -->
              </v-list-tile>
              <v-list-tile
                v-if="$auth.loggedIn && !$auth.user.first_name"
                :class="{ 'pr-0': $vuetify.breakpoint.smAndDown }"
                to="/profile"
              >
                <v-list-tile-content class="hidden-sm-and-down">
                  <v-list-tile-title>
                    Mi perfil
                  </v-list-tile-title>
                  <v-list-tile-sub-title>
                    {{
                      $auth.user && $auth.user.role ? $auth.user.role.name : ''
                    }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </v-toolbar-items>
        </v-flex>
        <div slot="extension" style="height:2px;width:100%">
          <v-progress-linear
            v-if="$wait.any && !$store.state.isHomeTop"
            :indeterminate="true"
            class="progress-header"
            color="primary"
            height="2"
          ></v-progress-linear>
        </div>
      </v-toolbar>
    </client-only>
    <v-dialog
      v-model="dialogRecoveryPassword"
      :max-width="500"
      :full-width="$vuetify.breakpoint.smAndDown"
      lazy
      scrollable
    >
      <v-card height="100%">
        <v-card-text :class="{ 'pa-0': this.$vuetify.breakpoint.xs }">
          <v-layout>
            <v-flex xs12 text-xs-center>
              <img
                :src="this.$store.state.brand.brand.logo"
                alt="Logo"
                height="56px"
              />
            </v-flex>
          </v-layout>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <br />
                <p class="text-xs-center">
                  {{ $t('password') }}
                </p>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="recoveryPass.email"
                  label="Correo *"
                  prepend-icon="email"
                  required
                ></v-text-field>
                <div id="recaptcha-password-reset" class="recaptcha"></div>
              </v-flex>
              <v-flex xs12>
                <v-btn
                  :loading="$wait.any"
                  :disabled="
                    $wait.any || !reCAPTCHARecoveryPASS || !recoveryPass.email
                  "
                  block
                  color="primary"
                  @click="sendPasswordResetEmail"
                  >{{ $t('btnpassword') }}</v-btn
                >
              </v-flex>
              <!-- <v-flex xs6 class="text-xs-left">
                <a
                  style="text-decoration:underline"
                  @click="
                    dialogLogin = false
                    dialogRecoveryPassword = false
                    dialogRegister = true
                  "
                >
                  {{ $t('notaccount') }}
                </a>
              </v-flex> -->
              <v-flex xs6 class="text-xs-right">
                <a
                  @click="
                    dialogRegister = false
                    dialogRecoveryPassword = false
                    dialogLogin = true
                  "
                >
                  {{ $t('account') }}
                </a>
              </v-flex>
              <br />
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogRegister"
      :max-width="500"
      :full-width="$vuetify.breakpoint.smAndDown"
      lazy
      scrollable
    >
      <v-card class="modalRegister" height="100%">
        <v-card-text :class="{ 'pa-0': this.$vuetify.breakpoint.xs }">
          <v-container grid-list-md>
            <v-tabs slot="extension" v-model="tabRegister" grow show-arrows>
              <v-tabs-slider></v-tabs-slider>
              <v-tab>{{ $t('email') }}</v-tab>
              <v-tab>{{ $t('mobile') }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabRegister">
              <v-tab-item>
                <form @submit.prevent="signUp">
                  <v-layout wrap>
                    <v-flex xs12>
                      <br />
                      <p class="text-xs-center text-uppercase">
                        {{ $t('join') }}
                        {{ $store.state.brand.brand.city_name }}!
                      </p>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="register.first_name"
                        :label="$t('name')"
                        prepend-icon="person"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12 md6>
                      <v-text-field
                        v-model="register.last_name"
                        :label="$t('lastname')"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="register.email"
                        :label="$t('email')"
                        prepend-icon="email"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="register.password"
                        :label="$t('password')"
                        prepend-icon="lock"
                        type="password"
                        required
                      ></v-text-field>
                      <div
                        id="recaptcha-header-register-email"
                        class="recaptcha"
                      ></div>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        :loading="$wait.any"
                        :disabled="
                          $wait.any ||
                            !reCAPTCHARegisterEMAIL ||
                            !register.email ||
                            !register.password ||
                            !register.first_name ||
                            !register.last_name
                        "
                        block
                        color="primary"
                        type="submit"
                      >
                        {{ $t('register') }}
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 class="text-xs-left">
                      <a
                        @click="
                          dialogRegister = false
                          dialogLogin = true
                          dialogRecoveryPassword = false
                        "
                      >
                        {{ $t('account') }}
                      </a>
                    </v-flex>
                    <v-flex xs6 class="text-xs-right">
                      <nuxt-link
                        to="privacy"
                        @click.native.stop="dialogRegister = false"
                      >
                        {{ $t('privacy') }}
                      </nuxt-link>
                    </v-flex>
                    <br />
                  </v-layout>
                </form>
              </v-tab-item>
              <v-tab-item>
                <form
                  v-show="!registerCode.show"
                  @submit.prevent="onRegisterSms"
                >
                  <v-layout wrap>
                    <v-flex xs12>
                      <br />
                      <p class="text-xs-center text-uppercase">
                        {{ $t('join') }}
                        {{ $store.state.brand.brand.city_name }}!
                      </p>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        v-model="register.first_name"
                        :label="$t('name')"
                        prepend-icon="person"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        v-model="register.last_name"
                        :label="$t('lastname')"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="register.phone"
                        :label="$t('phonenumber')"
                        :hint="$t('tendigits')"
                        mask="phone"
                        prepend-icon="phone"
                        persistent-hint
                        required
                      ></v-text-field>
                      <div
                        id="recaptcha-header-register-sms"
                        class="recaptcha"
                      ></div>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        :loading="$wait.any"
                        :disabled="
                          $wait.any ||
                            !reCAPTCHARegisterSMS ||
                            !register.phone ||
                            register.phone.length < 10
                        "
                        block
                        color="primary"
                        type="submit"
                      >
                        {{ $t('send') }}
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 class="text-xs-left">
                      <a
                        style="text-decoration:underline"
                        @click="
                          dialogRegister = false
                          dialogRecoveryPassword = false
                          dialogLogin = true
                        "
                      >
                        {{ $t('account') }}
                      </a>
                    </v-flex>
                    <v-flex xs6 class="text-xs-right">
                      <nuxt-link
                        to="privacy"
                        @click.native.stop="dialogRegister = false"
                      >
                        {{ $t('privacy') }}
                      </nuxt-link>
                    </v-flex>
                  </v-layout>
                </form>
                <form
                  v-show="registerCode.show"
                  @submit.prevent="onRegisterCode"
                >
                  <v-layout wrap>
                    <v-flex xs12>
                      <br />
                      <p class="text-xs-center">
                        {{ $t('smscode') }}
                      </p>
                      <v-text-field
                        v-model="registerCode.id"
                        :label="$t('verificationcode')"
                        prepend-icon="vpn_key"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        :loading="$wait.any"
                        :disabled="$wait.any"
                        block
                        color="primary"
                        type="submit"
                      >
                        {{ $t('register') }}
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 class="text-xs-left">
                      <a
                        style="text-decoration:underline"
                        @click="
                          dialogRegister = false
                          dialogRecoveryPassword = false
                          dialogLogin = true
                        "
                      >
                        {{ $t('account') }}
                      </a>
                    </v-flex>
                    <v-flex xs6 class="text-xs-right">
                      <a
                        style="text-decoration:underline"
                        @click="registerCode.show = false"
                      >
                        {{ $t('notcode') }}
                      </a>
                    </v-flex>
                  </v-layout>
                </form>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogLogin"
      :max-width="500"
      :full-width="$vuetify.breakpoint.smAndDown"
      lazy
      scrollable
    >
      <v-card class="modalLogin" height="100%">
        <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xs }">
          <v-container grid-list-md>
            <v-tabs slot="extension" v-model="tabLogin" grow show-arrows>
              <v-tabs-slider></v-tabs-slider>
              <v-tab>{{ $t('email') }}</v-tab>
              <v-tab>{{ $t('mobile') }}</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabLogin">
              <v-tab-item>
                <form @submit.prevent="signIn">
                  <v-layout wrap>
                    <v-flex xs12>
                      <br />
                      <p class="text-xs-center text-uppercase">
                        {{ $t('email&pass') }}
                      </p>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="login.email"
                        :label="$t('email')"
                        prepend-icon="email"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-text-field
                        v-model="login.password"
                        :label="$t('password')"
                        prepend-icon="lock"
                        type="password"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        :loading="$wait.any"
                        :disabled="$wait.any"
                        block
                        color="primary"
                        type="submit"
                      >
                        {{ $t('login') }}
                      </v-btn>
                    </v-flex>
                    <v-flex xs6 class="text-xs-left">
                      <a
                        style="text-decoration:underline"
                        @click="
                          dialogLogin = false
                          dialogRecoveryPassword = false
                          dialogRegister = true
                        "
                      >
                        {{ $t('notaccount') }}
                      </a>
                    </v-flex>
                    <v-flex xs6 class="text-xs-right">
                      <a
                        style="text-decoration:underline"
                        @click="
                          dialogLogin = false
                          dialogRegister = false
                          dialogRecoveryPassword = true
                        "
                      >
                        {{ $t('notpass') }}
                      </a>
                    </v-flex>
                    <br />
                  </v-layout>
                </form>
              </v-tab-item>
              <v-tab-item>
                <form v-show="!loginCode.show" @submit.prevent="onLoginSms">
                  <v-layout wrap>
                    <v-flex xs12>
                      <br />
                      <p class="text-xs-center text-uppercase">
                        {{ $t('smscode') }}
                      </p>
                      <v-text-field
                        v-model="login.phone"
                        :label="$t('phonenumber')"
                        :hint="$t('tendigits')"
                        mask="phone"
                        prepend-icon="phone"
                        persistent-hint
                        required
                      ></v-text-field>
                      <div
                        id="recaptcha-header-login-sms"
                        class="recaptcha"
                      ></div>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        :loading="$wait.any"
                        :disabled="
                          $wait.any ||
                            !reCAPTCHALoginSMS ||
                            !login.phone ||
                            login.phone.length < 10
                        "
                        block
                        color="primary"
                        type="submit"
                      >
                        {{ $t('send') }}
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 class="text-xs-left">
                      <a
                        style="text-decoration:underline"
                        @click="
                          dialogLogin = false
                          dialogRecoveryPassword = false
                          dialogRegister = true
                        "
                      >
                        {{ $t('notaccount') }}
                      </a>
                    </v-flex>
                  </v-layout>
                </form>
                <form v-show="loginCode.show" @submit.prevent="onLoginCode">
                  <v-layout wrap>
                    <v-flex xs12>
                      <br />
                      <p class="text-xs-center text-uppercase">
                        {{ $t('smscode') }}
                      </p>
                      <v-text-field
                        v-model="loginCode.id"
                        :label="$t('verificationcode')"
                        prepend-icon="vpn_key"
                        required
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-btn
                        :loading="$wait.any"
                        :disabled="$wait.any"
                        block
                        color="primary"
                        type="submit"
                      >
                        {{ $t('login') }}
                      </v-btn>
                    </v-flex>
                    <v-flex xs12 class="text-xs-right">
                      <a
                        style="text-decoration:underline"
                        @click="loginCode.show = false"
                      >
                        {{ $t('notcode') }}
                      </a>
                    </v-flex>
                  </v-layout>
                </form>
              </v-tab-item>
            </v-tabs-items>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogConfirmingActivitation"
      :full-width="$vuetify.breakpoint.smAndDown"
      lazy
      scrollable
      max-width="450"
      persistent
    >
      <v-card>
        <v-toolbar dark flat color="primary">
          <v-toolbar-title>Confirmar activación</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialogConfirmingActivitation = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text class="text-xs-center">
          <v-layout row wrap>
            <v-flex xs12>
              ¿Esta seguro que desea activar esta cuenta?
            </v-flex>
          </v-layout>
          <div id="recaptcha-activate-account" class="recaptcha"></div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            flat
            color="primary"
            @click="dialogConfirmingActivitation = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            :loading="$wait.any"
            :disabled="!reCAPTCHAActivateAccount"
            flat
            type="submit"
            color="primary"
            @click="activateUser"
          >
            Activar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.show"
      :top="true"
      :right="true"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      :multi-line="snackbar.mode === 'multi-line'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <v-btn dark flat @click.native="snackbar.show = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
  </div>
</template>

<i18n>
{
  "en": {
    "btnsignup": "Sign up",
    "btnsignin": "Sign in",
    "btnpassword": "Recover your password",
    "name": "Name",
    "lastname": "Last name",
    "signup": "Sign up",
    "signin": "Sign in",
    "register": "Register",
    "login": "Login",
    "password": "Enter your email to recover your password",
    "email": "Email",
    "mobile": "Mobile",
    "phonenumber": "Phone number",
    "password": "Password",
    "privacy": "Notice of Privacy",
    "notaccount": "You do not have an account?",
    "notpass": "Did you forget your password?",
    "account": "Already have an account?",
    "email&pass": "Use your email and password to login",
    "smscode": "Receive a code by sms to log in",
    "join": "Join",
    "tendigits": "To ten digits",
    "send": "Send",
    "notcode": "You did not receive your sms code?",
    "verificationcode": "Verification code"
  },
  "es": {
    "btnsignup": "Regístrate",
    "btnsignin": "Inicia sesión",
    "btnpassword": "Recupera tu contraseña",
    "name": "Nombre",
    "lastname": "Apellido",
    "signup": "Crear cuenta",
    "signin": "Iniciar sesión",
    "register": "Registrar",
    "login": "Ingresar",
    "password": "Ingresa tu correo para recuperar tu contraseña",
    "email": "Correo",
    "mobile": "Celular",
    "phonenumber": "Número de celular",
    "password": "Contraseña",
    "privacy": "Aviso de privacidad",
    "notaccount": "¿No tienes cuenta?",
    "notpass": "¿Olvidaste tu contraseña?",
    "account": "¿Ya tienes cuenta?",
    "email&pass": "Usa tu correo y contraseña para ingresar",
    "smscode": "Recibe un código para ingresar",
    "join": "¡Únete a",
    "tendigits": "A diez dígitos",
    "send": "Enviar",
    "notcode": "¿No recibiste tu código?",
    "verificationcode": "Código de verificación"
  }
}
</i18n>

<script>
import _ from 'lodash'
import axios from 'axios'
import async from 'async'
import firebase from 'firebase/app'
import 'firebase/auth'
import Sidebar from '@/components/public/Sidebar.vue'
import UserService from '@/services/UserService'

export default {
  components: {
    Sidebar
  },
  data() {
    return {
      isHome: this.$route.path === '/',
      locale: [
        { slug: 'es', text: 'Español' },
        { slug: 'en', text: 'Ingles' }
      ],
      tabRegister: null,
      tabLogin: null,
      tabRecoveyPass: null,
      reCAPTCHALoginSMS: false,
      reCAPTCHARegisterSMS: false,
      reCAPTCHARegisterEMAIL: false,
      reCAPTCHARecoveryPASS: false,
      reCAPTCHAActivateAccount: false,
      clipped: false,
      drawer: this.$auth.loggedIn && !this.$vuetify.breakpoint.smAndDown,
      dialogRegister: false,
      dialogRecoveryPassword: false,
      dialogLogin: false,
      dialogConfirmingActivitation: false,
      captchaActivateAccount: false,
      register: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        password: null
      },
      login: {
        email: null,
        password: null,
        phone: null
      },
      recoveryPass: {
        email: null
      },
      loginCode: {
        id: null,
        show: false,
        verificationId: null
      },
      registerCode: {
        id: null,
        show: false,
        verificationId: null
      },
      snackbar: {
        show: false,
        color: '',
        mode: '',
        timeout: 6000,
        text: '',
        right: '',
        top: ''
      },
      responseRegister: {}
    }
  },
  computed: {
    ismd4() {
      return this.$auth.loggedIn || this.$vuetify.breakpoint.mdAndDown
    }
  },
  watch: {
    $route(to) {
      this.isHome = to.path === '/'
      if (to.path === '/obras') {
        this.drawer = false
      }
    },
    dialogLogin(val) {
      this.snackbar = {
        show: false,
        text: '',
        color: ''
      }
      this.login = {
        email: '',
        password: ''
      }
      if (!val) {
        this.tabLogin = 0
      }
    },
    dialogRegister(val) {
      this.snackbar = {
        show: false,
        text: '',
        color: ''
      }
      this.register = {
        email: '',
        password: ''
      }
      if (!val) {
        this.tabRegister = 0
      }
    },
    dialogRecoveryPassword(val) {
      this.snackbar = {
        show: false,
        text: '',
        color: ''
      }
      this.recoveryPass = {
        email: '',
        password: ''
      }
      this.tabRecoveyPass = 0
    },
    tabRegister(val) {
      const self = this
      if (val === 0 && !window.recaptchaVerifierHeaderRegisterEmail) {
        window.recaptchaVerifierHeaderRegisterEmail = new firebase.auth.RecaptchaVerifier(
          'recaptcha-header-register-email',
          {
            callback() {
              self.reCAPTCHARegisterEMAIL = true
            },
            'expired-callback'() {
              self.reCAPTCHARegisterEMAIL = false
            },
            size: self.$vuetify.breakpoint.smAndDown ? 'compact' : 'normal'
          }
        )
        window.recaptchaVerifierHeaderRegisterEmail
          .render()
          .then(function(widgetId) {
            window.recaptchaWidgetHeaderRegisterEmailId = widgetId
          })
      } else if (val === 1 && !window.recaptchaVerifierHeaderRegisterSms) {
        window.recaptchaVerifierHeaderRegisterSms = new firebase.auth.RecaptchaVerifier(
          'recaptcha-header-register-sms',
          {
            callback() {
              self.reCAPTCHARegisterSMS = true
            },
            'expired-callback'() {
              self.reCAPTCHARegisterSMS = false
            },
            size: self.$vuetify.breakpoint.smAndDown ? 'compact' : 'normal'
          }
        )
        window.recaptchaVerifierHeaderRegisterSms
          .render()
          .then(function(widgetId) {
            window.recaptchaWidgetHeaderRegisterSmsId = widgetId
          })
      }
    },
    tabLogin(val) {
      const self = this
      if (val === 1 && !window.recaptchaVerifierHeaderLoginSms) {
        window.recaptchaVerifierHeaderLoginSms = new firebase.auth.RecaptchaVerifier(
          'recaptcha-header-login-sms',
          {
            callback() {
              self.reCAPTCHALoginSMS = true
            },
            'expired-callback'() {
              self.reCAPTCHALoginSMS = false
            },
            size: self.$vuetify.breakpoint.smAndDown ? 'compact' : 'normal'
          }
        )
        window.recaptchaVerifierHeaderLoginSms
          .render()
          .then(function(widgetId) {
            window.recaptchaWidgetHeaderLoginSmsId = widgetId
          })
      }
    },
    tabRecoveyPass(val) {
      const self = this
      setTimeout(function() {
        window.recaptchaVerifierPasswordReset = new firebase.auth.RecaptchaVerifier(
          'recaptcha-password-reset',
          {
            callback() {
              self.reCAPTCHARecoveryPASS = true
            },
            'expired-callback'() {
              self.reCAPTCHARecoveryPASS = false
            },
            size: self.$vuetify.breakpoint.smAndDown ? 'compact' : 'normal'
          }
        )
        window.recaptchaVerifierPasswordReset.render().then(function(widgetId) {
          window.recaptchaWidgetPasswordResetId = widgetId
        })
      }, 300)
    },
    captchaActivateAccount(val) {
      const self = this
      setTimeout(function() {
        window.recaptchaVerifierPasswordReset = new firebase.auth.RecaptchaVerifier(
          'recaptcha-activate-account',
          {
            callback() {
              self.reCAPTCHAActivateAccount = true
            },
            'expired-callback'() {
              self.reCAPTCHAActivateAccount = false
            },
            size: self.$vuetify.breakpoint.smAndDown ? 'compact' : 'normal'
          }
        )
        window.recaptchaVerifierPasswordReset.render().then(function(widgetId) {
          window.recaptchaWidgetPasswordResetId = widgetId
        })
      }, 300)
    }
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.$vuetify.lang.current = locale
      this.$store.dispatch('changeLocale', locale)
    },
    navigateTo(nav) {
      this.$router.push({
        path: nav
      })
    },
    reportAction(action) {
      if (action === 'map') this.navigateTo('/citizen/listener/map')
      else if (action === 'reports') this.navigateTo('/citizen/listener/list')
      else if (action === 'add-report-v1')
        this.navigateTo('/citizen/listener/create-v1')
      else if (action === 'add-report-v2')
        this.navigateTo('/citizen/listener/create-v2')
    },
    signUp() {
      this.$wait.start('full')
      this.$auth
        .login({
          type: 'register',
          first_name: this.register.first_name,
          last_name: this.register.last_name,
          email: this.register.email.trim(),
          password: this.register.password
        })
        .then(() => {
          this.dialogRegister = false
          this.responseRegister.message =
            'Se te ha enviado un correo de verificación.'
          this.responseRegister.color = 'success'
          this.$router.push({ path: '/profile' })
        })
        .catch(err => {
          this.responseRegister.message =
            err.message ||
            'La dirección de correo electrónico ya está en uso por otra cuenta.'
          this.responseRegister.color = 'error'
        })
        .finally(() => {
          this.$wait.end('full')
          this.snackbar.show = true
          this.snackbar.text = this.responseRegister.message
          this.snackbar.color = this.responseRegister.color
        })
    },
    signIn() {
      this.$wait.start('full')
      this.$auth
        .login({
          type: 'login',
          email: this.login.email.trim(),
          password: this.login.password
        })
        .then(() => {
          this.dialogLogin = false
          if (
            this.$auth.user.type_operator &&
            _.includes(this.$auth.user.type_operator, 'receptor')
          ) {
            this.$router.push({ path: '/admin/listener/new' })
          } else if (
            this.$auth.user.type_operator &&
            _.includes(this.$auth.user.type_operator, 'callcenter')
          ) {
            this.$router.push({ path: '/admin/listener/attention' })
          } else {
            this.$router.push({ path: '/' })
          }
        })
        .catch(err => {
          if (err.code === 'auth/user-disabled') {
            this.dialogConfirmingActivitation = true
            this.captchaActivateAccount = true
          } else if (
            err.code === 'auth/wrong-password' ||
            err.code === 'auth/user-not-found'
          ) {
            this.snackbar.show = true
            this.snackbar.text = 'Usuario o contraseña incorrectos.'
            this.snackbar.color = 'error'
          } else {
            this.snackbar.show = true
            this.snackbar.text =
              err.message ||
              'No hay registro de usuario correspondiente a este identificador. El usuario puede haber sido eliminado.'
            this.snackbar.color = 'error'
          }
        })
        .finally(() => {
          this.$wait.end('full')
        })
    },
    onLoginSms() {
      this.$wait.start('full')
      this.reCAPTCHALoginSMS = false
      let user = null
      async.waterfall(
        [
          callback => {
            UserService.getByPhone(this.login.phone)
              .then(res => {
                user = res
                callback(null, user)
              })
              .catch(err => {
                callback(err)
              })
          },
          (user, callback) => {
            if (!_.isEmpty(user)) {
              this.$store
                .dispatch('user/sendCode', {
                  phone: '+521' + this.login.phone,
                  verifier: window.recaptchaVerifierHeaderLoginSms
                })
                .then(res => {
                  this.loginCode.verificationId = res.verificationId
                  this.snackbar.show = true
                  this.snackbar.color = 'success'
                  this.snackbar.text =
                    'Código de verificación enviado con éxito.'
                  this.loginCode.show = true
                  callback(null)
                })
                .catch(err => {
                  callback(err)
                })
            } else {
              // eslint-disable-next-line
              callback({
                message:
                  'Este número no ha sido registrado, por favor registrate.'
              })
            }
          }
        ],
        err => {
          if (err) {
            this.snackbar.show = true
            this.snackbar.text = err.message
            this.snackbar.color = 'error'
          }
          this.$wait.end('full')
        }
      )
    },
    onLoginCode() {
      this.$wait.start('full')
      this.$auth
        .login({
          type: 'login',
          verificationId: this.loginCode.verificationId,
          code: this.loginCode.id.trim()
        })
        .then(() => {
          this.dialogLogin = false
          this.loginCode.show = false
          this.loginCode.id = null
          this.loginCode.verificationId = null
        })
        .catch(err => {
          if (err.code === 'auth/user-disabled') {
            this.dialogConfirmingActivitation = true
            this.captchaActivateAccount = true
          } else {
            this.snackbar.show = true
            this.snackbar.text = err.message
            this.snackbar.color = 'error'
          }
        })
        .finally(() => {
          this.$wait.end('full')
        })
    },
    onRegisterSms() {
      this.$wait.start('full')
      this.reCAPTCHARegisterSMS = false
      let user = null
      async.waterfall(
        [
          callback => {
            UserService.getByPhone(this.register.phone)
              .then(res => {
                user = res
                callback(null, user)
              })
              .catch(err => {
                callback(err)
              })
          },
          (user, callback) => {
            if (_.isEmpty(user)) {
              this.$store
                .dispatch('user/sendCode', {
                  phone: '+521' + this.register.phone,
                  verifier: window.recaptchaVerifierHeaderRegisterSms
                })
                .then(res => {
                  this.registerCode.verificationId = res.verificationId
                  this.snackbar.show = true
                  this.snackbar.color = 'success'
                  this.snackbar.text =
                    'Código de verificación enviado con éxito.'
                  this.registerCode.show = true
                  callback(null)
                })
                .catch(err => {
                  callback(err)
                })
            } else {
              // eslint-disable-next-line
              callback({
                message:
                  'Este número ya ha sido registrado, por favor inicia sesión.'
              })
            }
          }
        ],
        err => {
          if (err) {
            this.snackbar.show = true
            this.snackbar.text = err.message
            this.snackbar.color = 'error'
          }
          this.$wait.end('full')
        }
      )
    },
    onRegisterCode() {
      this.$wait.start('full')
      this.$auth
        .login({
          type: 'register',
          code: this.registerCode.id.trim(),
          first_name: this.register.first_name,
          last_name: this.register.last_name,
          phone: this.register.phone,
          verificationId: this.registerCode.verificationId
        })
        .then(() => {
          this.dialogRegister = false
          this.registerCode.show = false
          this.registerCode.id = null
          this.registerCode.verificationId = null
        })
        .catch(err => {
          this.snackbar.show = true
          this.snackbar.text = err.message
          this.snackbar.color = 'error'
        })
        .finally(() => {
          this.$wait.end('full')
        })
    },
    activateUser() {
      this.$wait.start('full')
      axios
        .post('/api/activate-user', {
          email: this.login.email.trim(),
          phone: this.login.phone
        })
        .then(res => {
          this.dialogConfirmingActivitation = false
          this.snackbar.show = true
          this.snackbar.color = 'success'
          this.snackbar.text = 'Cuenta activada con éxito.'
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$wait.end('full')
        })
    },
    sendPasswordResetEmail() {
      this.$wait.start('full')
      async.waterfall(
        [
          callback => {
            UserService.emailExist({ email: this.recoveryPass.email.trim() })
              .then(response => {
                if (parseInt(response.total) > 0) {
                  callback(null)
                } else {
                  // eslint-disable-next-line
                  callback('error', {
                    message:
                      'El email ingresado no existe. Por favor verificalo.'
                  })
                }
              })
              .catch(error => {
                callback(error, {
                  message:
                    'El email no pudo ser enviado. Por favor intenta nuevamente'
                })
              })
          },
          callback => {
            UserService.getByEmail({ email: this.recoveryPass.email.trim() })
              .then(res => {
                if (res.uid) {
                  callback(null, res.uid)
                } else {
                  // eslint-disable-next-line
                  callback('error', {
                    message: 'No se encontró registro del usuario.'
                  })
                }
              })
              .catch(err => {
                callback(err, {
                  message:
                    'El email no pudo ser enviado. Ocurrió un error al obtener el usuario.'
                })
              })
          },
          (uidUser, callback) => {
            axios
              .post('/api/create-token', {
                uid: uidUser,
                currentUrl: this.$store.state.currentUrl,
                mode: 'resetPassword'
              })
              .then(res => {
                callback(null, res.data)
              })
              .catch(err => {
                callback(err, {
                  message:
                    'El email no pudo ser enviado. Ocurrió un error al crear el token.'
                })
              })
          },
          (linkToChangePass, callback) => {
            axios
              .post('/api/send-email', {
                personalizations: [
                  {
                    to: this.recoveryPass.email.trim(),
                    dynamic_template_data: {
                      user: this.recoveryPass.email.trim(),
                      title: 'Restablece tu contraseña',
                      link: linkToChangePass,
                      content: `Visita este vínculo para restablecer la contraseña para tu cuenta ${this.recoveryPass.email}`,
                      subject: 'Restablece tu contraseña',
                      button: 'Continuar'
                    }
                  }
                ]
              })
              .then(() => {
                callback(null)
              })
              .catch(err => {
                callback(err, {
                  message:
                    'El email no pudo ser enviado. Por favor intenta nuevamente'
                })
              })
          }
        ],
        (err, message) => {
          if (err) {
            this.snackbar.show = true
            this.snackbar.text = message.message
            this.snackbar.color = 'error'
          } else {
            this.snackbar.show = true
            this.snackbar.text =
              'Correo de restablecimiento de contraseña enviado con éxito.'
            this.snackbar.color = 'success'
          }
          this.$wait.end('full')
        }
      )
    }
  }
}
</script>

<style lang="stylus" scoped>
>>>.theme--dark.v-list {
  background-color rgba(0,0,0,0) !important
}
>>>.v-toolbar .v-btn {
  text-transform unset
}
>>>.v-list__tile__avatar {
  justify-content flex-end
}
.transparent {
  box-shadow unset
}
.v-menu {
  .v-menu__activator {
    display flex
    &:hover {
      background-color #e5e5e5
    }
    .v-toolbar__title {
      font-size 14px
      margin-left 26px
      margin-right 18px
    }
  }
}
.v-toolbar {
  padding-top 2px
}
.v-progress-linear.progress-header {
  margin 0
}
</style>
