import { auth } from '~/plugins/firebase'

import { setClientAgency } from '~/services/AgencyService'
import { setClientAttachment } from '~/services/AttachmentService'
import { setClientBrand } from '~/services/BrandService'
import { setClientCategory } from '~/services/CategoryService'
import { setClientChangelog } from '~/services/ChangelogService'
import { setClientFollower } from '~/services/FollowerService'
import { setClientMenu } from '~/services/MenuService'
import { setClientModule } from '~/services/ModuleService'
import { setClientOperator } from '~/services/OperatorService'
import { setClientReport } from '~/services/ReportService'
import { setClientRole } from '~/services/RoleService'
import { setClientSecretary } from '~/services/SecretaryService'
import { setClientSetting } from '~/services/SettingService'
import { setClientSocialNetwork } from '~/services/SocialNetworkService'
import { setClientSubcategory } from '~/services/SubcategoryService'
import { setClientUser } from '~/services/UserService'
import { setClientAi } from '~/services/AIService'
import { setClientEmail } from '~/services/EmailService'
import { setClientMap } from '~/services/transparency/MapService.js'
import { setClientInitiative } from '~/services/transparency/InitiativeService.js'
import { setClientCatalog } from '~/services/transparency/CatalogService.js'
import { setClientCatalogFirst } from '~/services/transparency/CatalogFirstService.js'
import { setClientCatalogSecond } from '~/services/transparency/CatalogSecondService.js'

import { setClientStreets } from '~/services/urbanism/StreetsService.js'
import { setClientObras } from '~/services/urbanism/ObrasService.js'
import { setClientColonias } from '~/services/urbanism/ColoniasService.js'

import { setClientActivities } from '~/services/listener/ActivitiesService.js'
import { setClientMaterials } from '~/services/listener/MaterialsService.js'
import { setClientInstitution } from '~/services/listener/InstitutionService.js'
import { setClientMunicipios } from '~/services/listener/MunicipiosService.js'
import { setClientComplaint } from '~/services/listener/ComplaintService.js'
import { setClientConformities } from '~/services/listener/ConformitiesService.js'
import { setClientNoConformities } from '~/services/listener/NoConformitiesService.js'
import { setClientCauses } from '~/services/listener/CausesService.js'

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

export default function({ $axios, app }) {
  $axios.setToken(app.$auth.getToken('firebase'), 'Bearer')
  setClientAgency($axios)
  setClientAttachment($axios)
  setClientBrand($axios)
  setClientCategory($axios)
  setClientChangelog($axios)
  setClientFollower($axios)
  setClientMenu($axios)
  setClientModule($axios)
  setClientOperator($axios)
  setClientReport($axios)
  setClientRole($axios)
  setClientSecretary($axios)
  setClientSetting($axios)
  setClientSocialNetwork($axios)
  setClientSubcategory($axios)
  setClientUser($axios)
  setClientAi($axios)
  setClientMap($axios)
  setClientInitiative($axios)
  setClientCatalog($axios)
  setClientCatalogFirst($axios)
  setClientCatalogSecond($axios)
  setClientObras($axios)
  setClientStreets($axios)
  setClientColonias($axios)
  setClientEmail($axios)
  setClientMaterials($axios)
  setClientMunicipios($axios)
  setClientInstitution($axios)
  setClientComplaint($axios)
  setClientActivities($axios)
  setClientConformities($axios)
  setClientNoConformities($axios)
  setClientCauses($axios)

  $axios.onError(error => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then(token => {
            originalRequest.headers.Authorization = 'Bearer ' + token
            return $axios(originalRequest)
          })
          .catch(err => {
            return Promise.reject(err)
          })
      }

      if (
        originalRequest.headers.Authorization !== app.$auth.getToken('firebase')
      ) {
        originalRequest.headers.Authorization = app.$auth.getToken('firebase')
        return Promise.resolve($axios(originalRequest))
      }

      originalRequest._retry = true
      isRefreshing = true

      return new Promise(function(resolve, reject) {
        const user = auth.currentUser
        if (user) {
          user
            .getIdToken(true)
            .then(idToken => {
              if (idToken !== app.$auth.getToken('firebase')) {
                $axios.setToken(idToken, 'Bearer')
                app.$auth.setToken('firebase', idToken)
                originalRequest.headers.Authorization = 'Bearer ' + idToken
                processQueue(null, idToken)
                resolve($axios(originalRequest))
              }
            })
            .catch(err => {
              processQueue(err, null)
              reject(err)
            })
            .then(() => {
              isRefreshing = false
            })
        }
      })
    }

    return Promise.reject(error)
  })
}
