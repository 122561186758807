let client

export function setClientAgency(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/agency/create', data)
  },
  update: data => {
    return client.$put('/agency/update', data)
  },
  list: () => {
    return client.$post('/agency/list')
  },
  getById: (parent, id) => {
    return client.$post('/agency/getbyid/' + parent + '/' + id)
  },
  getByParent: id => {
    return client.$post('/agency/get-by-parent/' + id)
  },
  delete: (parent, id) => {
    return client.$post('/agency/delete/' + parent + '/' + id)
  }
}
