import _ from 'lodash'
import async from 'async'
import axios from 'axios'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/browser'
import firebase from 'firebase/app'

import { auth } from '@/plugins/firebase'

export default class FirebaseScheme {
  constructor(auth, options) {
    this.auth = auth
    this.options = Object.assign(
      {
        returnSecureToken: true
      },
      options
    )
  }

  login(authData) {
    const self = this
    return new Promise(function(resolve, reject) {
      if (authData.type === 'login' && !authData.verificationId) {
        auth
          .signInWithEmailAndPassword(authData.email, authData.password)
          .then(user => {
            self.auth.setRefreshToken('firebase', user.user.refreshToken)
            self.auth
              .fetchUserOnce()
              .then(() => {
                resolve()
              })
              .catch(err => {
                reject(err)
              })
          })
          .catch(err => {
            reject(err)
          })
      } else if (authData.type === 'login' && authData.verificationId) {
        const credential = firebase.auth.PhoneAuthProvider.credential(
          authData.verificationId,
          authData.code
        )
        auth
          .signInAndRetrieveDataWithCredential(credential)
          .then(user => {
            self.auth.setRefreshToken('firebase', user.user.refreshToken)
            self.auth
              .fetchUserOnce()
              .then(() => {
                resolve()
              })
              .catch(err => {
                reject(err)
              })
          })
          .catch(err => {
            reject(err)
          })
      } else if (authData.type === 'register' && !authData.verificationId) {
        async.waterfall(
          [
            callback => {
              firebase
                .auth()
                .createUserWithEmailAndPassword(
                  authData.email,
                  authData.password
                )
                .then(user => {
                  self.auth.setRefreshToken('firebase', user.user.refreshToken)
                  callback(null, user.user)
                })
                .catch(err => {
                  callback(err)
                })
            },
            (user, callback) => {
              user
                .getIdToken()
                .then(idToken => {
                  self.auth.ctx.$axios.setToken(idToken, 'Bearer')
                  callback(null, user)
                })
                .catch(err => {
                  callback(err)
                })
            },
            (user, callback) => {
              self.auth.ctx.$axios
                .$post('/users/get-by-email/', {
                  email: user.email
                })
                .then(res => {
                  let userDb = null
                  if (res) {
                    userDb = res
                  }
                  callback(null, user, userDb)
                })
                .catch(err => {
                  callback(err)
                })
            },
            (user, userDb, callback) => {
              if (userDb && userDb.id) {
                self.auth.ctx.$axios
                  .$put('/users/update', {
                    id: userDb.id,
                    uid: user.uid,
                    first_name: authData.first_name,
                    last_name: authData.last_name,
                    email: userDb.email ? userDb.email : null,
                    phone: userDb.phone ? userDb.phone : null,
                    role: userDb.role && userDb.role.id ? userDb.role.id : null
                  })
                  .then(() => {
                    user
                      .updateProfile({
                        displayName: userDb.first_name
                      })
                      .then(() => {
                        callback(null)
                      })
                      .catch(err => {
                        callback(err)
                      })
                    self.sendEmailVerificationSengrid(
                      user,
                      self.auth.ctx.store.state.currentUrl
                    )
                  })
                  .catch(err => {
                    callback(err)
                  })
              } else {
                self.auth.ctx.$axios
                  .$post('/users/create', {
                    uid: user.uid,
                    first_name: authData.first_name,
                    last_name: authData.last_name,
                    email: authData.email ? authData.email : null,
                    phone: authData.phone ? authData.phone : null
                  })
                  .then(() => {
                    self.sendEmailVerificationSengrid(
                      user,
                      self.auth.ctx.store.state.currentUrl
                    )
                    callback(null)
                  })
                  .catch(err => {
                    callback(err)
                  })
              }
            },
            callback => {
              self.auth
                .fetchUserOnce()
                .then(() => {
                  callback(null)
                })
                .catch(err => {
                  callback(err)
                })
            }
          ],
          err => {
            if (err) {
              return reject(err)
            } else {
              return resolve()
            }
          }
        )
      } else if (authData.type === 'register' && authData.verificationId) {
        const credential = firebase.auth.PhoneAuthProvider.credential(
          authData.verificationId,
          authData.code
        )
        async.waterfall(
          [
            callback => {
              auth
                .signInAndRetrieveDataWithCredential(credential)
                .then(user => {
                  self.auth.setRefreshToken('firebase', user.user.refreshToken)
                  callback(null, user.user)
                })
                .catch(err => {
                  callback(err)
                })
            },
            (user, callback) => {
              axios
                .post('/api/get-securetoken', {
                  refreshToken: self.auth.getRefreshToken('firebase')
                })
                .then(res => {
                  const authData = res.data
                  self.auth.ctx.$axios.setToken(authData.id_token, 'Bearer')
                  callback(null, user)
                })
                .catch(err => {
                  callback(err)
                })
            },
            (user, callback) => {
              self.auth.ctx.$axios
                .$post('/users/create', {
                  uid: user.uid,
                  first_name: authData.first_name,
                  last_name: authData.last_name,
                  phone: authData.phone,
                  email: null
                })
                .then(() => {
                  callback(null, user)
                })
                .catch(err => {
                  callback(err)
                })
            },
            (user, callback) => {
              self.auth.ctx.$axios
                .$post('/users/update-activated', {
                  activated: true,
                  uid: user.uid
                })
                .then(() => {
                  callback(null)
                })
                .catch(err => {
                  callback(err)
                })
            },
            callback => {
              self.auth
                .fetchUserOnce()
                .then(() => {
                  callback(null)
                })
                .catch(err => {
                  callback(err)
                })
            }
          ],
          err => {
            if (err) {
              reject(err)
            } else {
              resolve()
            }
          }
        )
      }
    })
  }

  sendEmailVerificationSengrid(user, currentUrl) {
    const self = this
    async.waterfall(
      [
        callback => {
          self.auth.ctx.$axios
            .$post('/users/email-exist/', {
              email: user.email
            })
            .then(response => {
              if (parseInt(response.total) > 0) {
                // Create token for send in email
                axios
                  .post('/api/create-token', {
                    uid: user.uid,
                    currentUrl,
                    mode: 'verifyEmail'
                  })
                  .then(res => {
                    callback(null, res.data)
                  })
                  .catch(err => {
                    callback(err, {
                      message:
                        'El email no pudo ser enviado. Por favor intenta nuevamente'
                    })
                  })
              } else {
                // eslint-disable-next-line
                callback('error', {
                  message: 'El usuario no existe. Por favor verificalo.'
                })
              }
            })
            .catch(error => {
              callback(error, {
                message:
                  'El email no pudo ser enviado. Por favor intenta nuevamente'
              })
            })
        },
        (linkToVerify, callback) => {
          axios
            .post('/api/send-email', {
              personalizations: [
                {
                  to: user.email,
                  dynamic_template_data: {
                    user: user.email,
                    title: 'Verifica tu correo',
                    link: linkToVerify,
                    content:
                      'Visita este vínculo para verificar tu dirección de correo electrónico.',
                    subject: 'Verifica tu correo',
                    button: 'Continuar'
                  }
                }
              ]
            })
            .then(() => {
              callback(null)
            })
            .catch(err => {
              callback(err, {
                message: err.message
              })
            })
        }
      ],
      (err, message) => {
        if (err) {
          console.error(err)
        } else {
          /** @todo Display message in a notification? */
          // console.log(message)
        }
      }
    )
  }

  /**
   * TODO Revisar porque entra dos veces
   */

  fetchUser() {
    const self = this
    let protocol = 'https://'
    let host = ''
    if (process.server) {
      if (this.auth.ctx.req.headers.host.includes('localhost')) {
        protocol = 'http://'
      }
      host = this.auth.ctx.req.headers.host
    } else {
      if (this.auth.ctx.store.state.currentUrl.includes('localhost')) {
        protocol = 'http://'
      }
      host = this.auth.ctx.store.state.currentUrl
    }
    return new Promise((resolve, reject) => {
      if (!self.auth.getRefreshToken('firebase')) {
        self.auth.logout()
        return resolve()
      }
      async.waterfall(
        [
          callback => {
            axios
              .post(`${protocol}${host}/api/get-securetoken`, {
                refreshToken: self.auth.getRefreshToken('firebase')
              })
              .then(res => {
                const authData = res.data
                self.auth.setToken('firebase', authData.id_token)
                self.auth.ctx.$axios.setToken(authData.id_token, 'Bearer')
                callback(null, authData)
              })
              .catch(err => {
                callback(err)
              })
          },
          (authData, callback) => {
            self.auth.ctx.$axios
              .$post('/users/getbyuid/' + authData.user_id)
              .then(res => {
                const userDatastore = res
                if (_.isEmpty(userDatastore)) {
                  const err = {
                    code: 'auth/user-not-found',
                    message:
                      'There is no user record corresponding to this identifier. The user may have been deleted.'
                  }
                  callback(err)
                } else {
                  userDatastore.emailVerified = userDatastore.activated
                  callback(null, userDatastore)
                }
              })
              .catch(err => {
                callback(err)
              })
          }
        ],
        (err, result) => {
          if (err) {
            return reject(err)
          } else {
            self.auth.setUser(result)
            LogRocket.identify(result.id, {
              id: result.id,
              uid: result.uid,
              first_name: result.first_name,
              last_name: result.last_name,
              email: result.email,
              phone: result.phone,
              type: result.type,
              emailVerified: result.emailVerified,
              credibility: result.credibility
            })
            Sentry.configureScope(scope => {
              scope.setUser({
                id: result.id,
                uid: result.uid,
                first_name: result.first_name,
                last_name: result.last_name,
                email: result.email,
                phone: result.phone,
                type: result.type,
                emailVerified: result.emailVerified,
                credibility: result.credibility
              })
            })
            return resolve()
          }
        }
      )
    })
  }
}
