let client

export function setClientChangelog(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/changelog/create', data)
  },
  get: id => {
    return client.$post('/changelog/get/' + id)
  }
}
