<template>
  <svg class="oscityIcon" viewBox="0 0 59.9 59.77">
    <path
      d="M57.54,23.41c-1.17,0-2.34,0-3.51,0a.76.76,0,0,1-.57-.31c-.71-1.78-1.4-3.56-2.13-5.47.87-.86,1.86-1.84,2.84-2.83a1.81,1.81,0,0,0,0-3L48,5.67a1.82,1.82,0,0,0-3,0c-.88.87-1.74,1.76-2.64,2.61a.83.83,0,0,1-.68.14c-1.74-.74-3.47-1.51-5.3-2.31V2a1.79,1.79,0,0,0-2-2H25.52a1.79,1.79,0,0,0-2,2c0,1.39,0,2.79,0,4.24-.61.2-1.22.37-1.8.61-1.14.48-2.26,1-3.37,1.51a.63.63,0,0,1-.89-.13c-.87-.91-1.76-1.79-2.66-2.68a1.79,1.79,0,0,0-2.78,0C9.87,7.68,7.74,9.81,5.63,11.93a1.75,1.75,0,0,0,0,2.76c1,1,2,2,2.94,2.93-.74,1.9-1.42,3.69-2.14,5.47a.74.74,0,0,1-.57.31c-1.16,0-2.33,0-3.5,0C.9,23.42.55,23.65,0,25v9.75c.55,1.36.88,1.57,2.37,1.58,1.17,0,2.33,0,3.5,0a.82.82,0,0,1,.58.39c.71,1.75,1.39,3.51,2.12,5.39L5.73,45a1.81,1.81,0,0,0,0,3l6.16,6.16a1.82,1.82,0,0,0,3,0l2.88-2.87,5.76,2.37c0,1.21.05,2.52,0,3.82a2.25,2.25,0,0,0,1.26,2.35H35.17a2.27,2.27,0,0,0,1.25-2.42c-.06-1.28,0-2.56,0-3.75l5.77-2.38c.7.7,1.56,1.49,2.32,2.36,1.55,1.73,2.54,1.59,4,0,1.84-2,3.78-3.8,5.67-5.69a1.82,1.82,0,0,0,0-3l-2.84-2.84c.73-1.87,1.4-3.64,2.12-5.38a.78.78,0,0,1,.58-.39c1.16,0,2.33,0,3.5,0,1.48,0,1.82-.23,2.37-1.58V25C59.36,23.66,59,23.42,57.54,23.41ZM57,33.54H51.9c-.32,0-.57,0-.65.41a22.69,22.69,0,0,1-3.58,8.44c1.35,1.34,2.69,2.65,4,3.93l-5.2,5.18-3.92-3.93a23.34,23.34,0,0,1-8.88,3.69v5.67H26.28V51.27a23.15,23.15,0,0,1-8.84-3.66c-1.36,1.36-2.66,2.68-3.9,3.93L8.35,46.35l3.9-3.88A23.08,23.08,0,0,1,9.16,36c-.18-.64-.36-1.28-.49-1.93-.08-.42-.25-.55-.68-.55-1.47,0-2.93,0-4.4,0l-.7,0V26.22H7.5c1.29,0,.94.13,1.29-1a41.69,41.69,0,0,1,1.36-4.09c.55-1.3,1.29-2.52,2-3.81L8.26,13.47l5.2-5.21,3.79,3.84c1.6-.81,3-1.62,4.46-2.23s3-1,4.56-1.5V2.81H33.6V8.49a22.77,22.77,0,0,1,8.81,3.64c1.37-1.37,2.69-2.69,4-4l5.22,5.22c-1.27,1.25-2.6,2.57-3.93,3.9.19.32.31.56.46.79a21.12,21.12,0,0,1,3,7.31c.13.65.38.89,1.07.86,1.58-.06,3.16,0,4.79,0Z"
    />
    <path
      d="M28.34,18.17A11.94,11.94,0,0,0,29.92,42a10.56,10.56,0,0,0,5-1.13,1.81,1.81,0,0,0,.89-1.09c.24-1.11-.85-1.78-2-1.28a9.29,9.29,0,1,1,4.92-11.36,9.17,9.17,0,0,1,.16,5.34,1.34,1.34,0,0,0,1,1.82c.78.17,1.38-.27,1.62-1.19A12,12,0,0,0,28.34,18.17Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'SettingsIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
