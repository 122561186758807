import _ from 'lodash'

export default function(context) {
  if (!context.app.$auth.user.emailVerified && context.app.$auth.user.email) {
    return context.redirect('/not-validated')
  }
  if (
    (context.app.$auth.user.type !== 'superadmin' &&
      context.app.$auth.user.type !== 'user' &&
      context.app.$auth.user.type !== 'operator') ||
    (context.app.$auth.user.type === 'operator' &&
      context.app.$auth.user.type_operator.includes('ejecutor')) ||
    (context.app.$auth.user.allowed_categories &&
      context.app.$auth.user.allowed_categories.length <= 0) ||
    (context.app.$auth.user.role &&
      !context.app.$auth.user.role.views.includes('listener')) ||
    _.find(context.store.state.module.modules, ['slug', 'listener']).active ===
      false
  ) {
    return context.redirect('/forbidden')
  }
}
