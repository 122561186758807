
export const vueI18n = {"fallbackLocale":"en"}

export const vueI18nLoader = true

export const locales = [{"code":"es","iso":"es-ES"},{"code":"en","iso":"en-US"}]

export const defaultLocale = 'es'

export const routesNameSeparator = '___'

export const defaultLocaleRouteNameSuffix = 'default'

export const strategy = 'prefix_except_default'

export const lazy = false

export const langDir = null

export const rootRedirect = null

export const detectBrowserLanguage = false

export const differentDomains = false

export const forwardedHost = false

export const seo = false

export const baseUrl = ''

export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}

export const parsePages = true

export const pages = {}

export const encodePaths = true

export const beforeLanguageSwitch = () => null

export const onLanguageSwitched = () => null

export const MODULE_NAME = 'nuxt-i18n'

export const LOCALE_CODE_KEY = 'code'

export const LOCALE_ISO_KEY = 'iso'

export const LOCALE_DOMAIN_KEY = 'domain'

export const LOCALE_FILE_KEY = 'file'

export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}

export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'

export const localeCodes = ["es","en"]
