let client

export function setClientSecretary(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/secretary/create', data)
  },
  update: data => {
    return client.$post('/secretary/update', data)
  },
  get: id => {
    return client.$post('/secretary/getbyid/' + id)
  },
  list: () => {
    return client.$post('/secretary/list')
  },
  hasCategories: () => {
    return client.$post('/secretary/hasCategories')
  },
  delete: id => {
    return client.$post('/secretary/delete/' + id)
  },
  listTransparency: () => {
    return client.$post('/secretary/list-transparency')
  }
}
