let client

export function setClientModule(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/module/create', data)
  },
  update: data => {
    return client.$post('/module/update', data)
  },
  activate: data => {
    return client.$post('/module/activate', data)
  },
  get: slug => {
    return client.$post('/module/get/' + slug)
  },
  addElement: data => {
    return client.$post('/module/add-element', data)
  },
  removeElement: data => {
    return client.$post('/module/remove-element', data)
  },
  list: () => {
    return client.$post('/module/list')
  },
  delete(id) {
    return client.$post('/module/delete/' + id)
  }
}
