let client

export function setClientEmail(newclient) {
  client = newclient
}

export default {
  sendCorreo: data => {
    return client.$post('/email/send-correo', data)
  }
}
