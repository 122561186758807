let client

export function setClientComplaint(newclient) {
  client = newclient
}

export default {
  create: data => {
    return client.$post('/complaint/create', data)
  },
  update: data => {
    return client.$post('/complaint/update', data)
  },
  createMulti: data => {
    return client.$post('/complaint/createMulti', data)
  },
  list: () => {
    return client.$post('/complaint/list/')
  },
  listByType: data => {
    return client.$post('/complaint/listByType', data)
  },
  get: (secretary, agency, id) => {
    return client.$post(`/complaint/get/${id}`)
  },
  delete: (secretary, agency, id) => {
    return client.$post(`/complaint/delete/${id}`)
  }
}
