<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 190.65 190.65"
  >
    <path
      class="oscityIcon"
      d="M95.53,30.6h-.22A64.61,64.61,0,0,0,47.48,138.76L34,152.22a3.12,3.12,0,0,0,4.41,4.41l13.46-13.46A64.68,64.68,0,1,0,95.53,30.6Zm2.9,9.6c8.76,7.67,26.32,26.18,27.67,52H98.43ZM92.2,37V92.2H64.5c1-18.72,10.43-33.49,18.45-42.75a3.12,3.12,0,1,0-4.72-4.08c-8.71,10.07-19,26.2-20,46.83H37A58.5,58.5,0,0,1,92.2,37ZM37,98.43H58.25c1.2,26.27,17.26,45.16,27.32,54.49A58.5,58.5,0,0,1,37,98.43Zm55.22,52c-8.77-7.66-26.32-26.18-27.67-52H92.2Zm6.23,3.22V98.43h27.7c-1,19.6-11.28,34.91-20,44.48a3.11,3.11,0,1,0,4.61,4.19c9.45-10.38,20.6-27.09,21.61-48.67h11a3.12,3.12,0,0,0,0-6.23h-11c-1.2-26.27-17.26-45.16-27.32-54.49a58.41,58.41,0,0,1-6.63,115.94Z"
    />
    <path
      class="oscityIcon"
      d="M148.45,45.32a3.15,3.15,0,0,0,2.21-.91l15-15A15.29,15.29,0,0,0,174.13,32a15.44,15.44,0,1,0-15.44-15.44A15.29,15.29,0,0,0,161.25,25l-15,15a3.12,3.12,0,0,0,2.2,5.32Zm25.68-38a9.2,9.2,0,1,1-9.2,9.2A9.21,9.21,0,0,1,174.13,7.32Z"
    />
    <path
      class="oscityIcon"
      d="M16.52,158.69A15.44,15.44,0,1,0,32,174.13,15.46,15.46,0,0,0,16.52,158.69Zm0,24.65a9.21,9.21,0,1,1,9.2-9.21A9.22,9.22,0,0,1,16.52,183.34Z"
    />
    <path
      class="oscityIcon"
      d="M16.52,32A15.31,15.31,0,0,0,25,29.4l16,16A3.12,3.12,0,1,0,45.41,41l-16-16A15.29,15.29,0,0,0,32,16.52,15.44,15.44,0,1,0,16.52,32Zm0-24.64a9.2,9.2,0,1,1-9.2,9.2A9.21,9.21,0,0,1,16.52,7.32Z"
    />
    <path
      class="oscityIcon"
      d="M174.13,158.69a15.29,15.29,0,0,0-8.47,2.56l-15.89-15.89a3.12,3.12,0,1,0-4.41,4.41l15.89,15.89a15.42,15.42,0,1,0,12.88-7Zm0,24.65a9.21,9.21,0,1,1,9.2-9.21A9.22,9.22,0,0,1,174.13,183.34Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'WorldConnIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
