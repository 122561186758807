<template>
  <svg class="oscityIcon" viewBox="0 0 59.9 59.77">
    <path
      d="M58.94,31.12a1.51,1.51,0,0,0-1.61-1.61H54.79V25a1.54,1.54,0,0,0-1.7-1.7H45.75c0-.1,0-.18-.08-.26-.31-.92-.55-1.87-.94-2.75A16.76,16.76,0,0,0,31.2,10.35c-.09,0-.23-.07-.24-.12,0-.33,0-.66,0-1h7.61a1.5,1.5,0,0,0,1.57-1.57c0-2,0-4,0-6A1.61,1.61,0,0,0,38.47,0c-3,0-6,0-9,0a1.71,1.71,0,0,0-.55.09,1.47,1.47,0,0,0-1,1.45v8.73c-7.72,1.17-12.59,5.51-14.77,13H5.83a1.52,1.52,0,0,0-1.67,1.67c0,1.41,0,2.82,0,4.23v.31l-.1,0H1.6A1.51,1.51,0,0,0,0,31.08v27a1.49,1.49,0,0,0,1.58,1.61H31.39c2,0,3.94,0,5.9,0,.61,0,1.21,0,1.81,0H57.26a1.92,1.92,0,0,0,.58-.07,1.49,1.49,0,0,0,1.1-1.56V31.12Zm-28-28h6.12v3.1H31ZM29.36,13.31a13.57,13.57,0,0,1,13.21,10H16.38A13.54,13.54,0,0,1,29.36,13.31Zm-22.14,13h44.5v3.2H7.22Zm25,30.31h-5.4V47.34h5.4Zm23.73,0H40.74V40.34a1.52,1.52,0,1,0-3-.06c0,2.46,0,4.93,0,7.39v8.95H35.24c0-.09,0-.19,0-.29V46a1.5,1.5,0,0,0-1.61-1.66H25.27a1.51,1.51,0,0,0-1.56,1.56c0,2.37,0,4.74,0,7.12v3.47c0,.05,0,.11,0,.18H21.24V40.3a1.52,1.52,0,0,0-2.62-1.08,1.46,1.46,0,0,0-.42,1.07q0,4.29,0,8.6v7.72H3.05v-24H55.9Z"
    />
    <path
      d="M25.94,42.76H33a1.54,1.54,0,0,0,1.67-1.65V35.76A1.54,1.54,0,0,0,33,34.12H25.92a1.53,1.53,0,0,0-1.64,1.64c0,1.78,0,3.55,0,5.32A1.54,1.54,0,0,0,25.94,42.76Zm1.4-5.57H31.6V39.7H27.34Z"
    />
    <path
      d="M44.33,42.76h7a1.55,1.55,0,0,0,1.72-1.71V35.81a1.55,1.55,0,0,0-1.69-1.69h-7a1.54,1.54,0,0,0-1.67,1.68c0,1.76,0,3.53,0,5.29A1.54,1.54,0,0,0,44.33,42.76Zm1.39-5.57H50V39.7H45.72Z"
    />
    <path
      d="M52,45.81a2.1,2.1,0,0,0-.66-.09H44.05a1.51,1.51,0,0,0-1.39,1.55q0,2.7,0,5.4a1.53,1.53,0,0,0,1.69,1.68h7.09a1.52,1.52,0,0,0,1.61-1.62V47.42A1.61,1.61,0,0,0,52,45.81ZM50,51.3H45.72V48.78H50Z"
    />
    <path
      d="M16.11,46.55a1.5,1.5,0,0,0-1.5-.83c-1.68,0-3.35,0-5,0-.67,0-1.34,0-2,0A1.53,1.53,0,0,0,5.9,47.39c0,1.77,0,3.53,0,5.29a1.53,1.53,0,0,0,1.69,1.68h7a2.3,2.3,0,0,0,.69-.1,1.5,1.5,0,0,0,1-1.55c0-1.8,0-3.6,0-5.4A1.8,1.8,0,0,0,16.11,46.55ZM13.23,51.3H9V48.79h4.28Z"
    />
    <path
      d="M7.49,42.76c.82,0,1.64,0,2.47,0s1.84,0,2.77,0c.67,0,1.34,0,2,0a1.51,1.51,0,0,0,1.55-1.61V35.78a1.54,1.54,0,0,0-1.67-1.66H7.51a1.54,1.54,0,0,0-1.62,1.57c0,1.82,0,3.64,0,5.46A1.51,1.51,0,0,0,7.49,42.76ZM9,37.19h4.27V39.7H9Z"
    />
    <path
      d="M19.71,37.48A1.48,1.48,0,0,0,21.23,36a1.5,1.5,0,0,0-1.5-1.54A1.52,1.52,0,0,0,18.2,36,1.5,1.5,0,0,0,19.71,37.48Z"
    />
    <path
      d="M39.21,37.49A1.52,1.52,0,1,0,37.71,36,1.52,1.52,0,0,0,39.21,37.49Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'CityHallIcon'
}
</script>

<style lang="stylus" scoped>
.oscityIcon
  fill: currentColor
path
  stroke: currentColor
</style>
